import type { Node, NodeId } from '@timelinefyi/types'
import { Alert, Button, Card, List, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Title } = Typography

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: 1em 0;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5em;
`

const Section = styled.section``

type Props = {
    isGodMode: boolean
    locked: boolean
    isFuture: boolean
    nodeList: Node[]
    nodeRedirectCallback: (node: NodeId | undefined) => void
}

const RedirectSection: React.FC<Props> = ({
    isGodMode,
    locked,
    isFuture,
    nodeList,
    nodeRedirectCallback,
}) => {
    if (!locked) return <div />
    if (!isFuture) {
        const description = isGodMode ? (
            <span>
                You&apos;re in God Mode! Click &quot;
                <strong>Rollback One Step</strong>&quot; to revise your path.
            </span>
        ) : (
            <span>
                <strong>
                    Switch to &quot;God Mode 💫&quot; to revise your path.
                </strong>
            </span>
        )
        return (
            <Alert
                type={isGodMode ? 'success' : 'warning'}
                showIcon={!isGodMode}
                message="Some statistics are locked 🔒 because they are path-based statistics!"
                description={description}
            />
        )
    }

    if (isGodMode) {
        return (
            <Wrapper>
                <Card>
                    <Content>
                        <Section>
                            <Alert
                                type="success"
                                message="Some statistics are locked 🔒 because you haven't unlock one of the following stages!"
                                description={
                                    <div>
                                        <span>
                                            You&apos;re in God Mode 💫.{' '}
                                            <strong>
                                                You don&apos;t need to submit
                                                time to unlock them.
                                            </strong>
                                        </span>
                                    </div>
                                }
                            />
                        </Section>
                        <Section>
                            <Title level={5}>
                                Please submit your time in one of the following
                                steps:
                            </Title>
                            <List
                                itemLayout="horizontal"
                                dataSource={nodeList}
                                renderItem={(data) => (
                                    <List.Item
                                        actions={[
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    nodeRedirectCallback(
                                                        data.id,
                                                    )
                                                }
                                            >
                                                Go
                                            </Button>,
                                        ]}
                                    >
                                        {data.name as string}
                                    </List.Item>
                                )}
                            />
                        </Section>
                    </Content>
                </Card>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Card>
                <Content>
                    <Section>
                        <Alert
                            type="warning"
                            showIcon
                            message="Some statistics are locked 🔒 because you have not submit your time in one of the following stages!"
                            description={
                                <div>
                                    <span>
                                        <strong>
                                            Not because we&apos;re trying to
                                            hide it from you!! 😢
                                        </strong>
                                    </span>
                                    <br />
                                    <span>
                                        It&apos;s fundamentally impossible for
                                        us to provide you those statistics
                                        without you submit the time in the
                                        following stages.
                                    </span>
                                </div>
                            }
                        />
                    </Section>
                    <Section>
                        <Title level={5}>
                            Please submit your time in one of the following
                            steps:
                        </Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={nodeList}
                            renderItem={(data) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                nodeRedirectCallback(data.id)
                                            }
                                        >
                                            Go
                                        </Button>,
                                    ]}
                                >
                                    {data.name as string}
                                </List.Item>
                            )}
                        />
                    </Section>
                </Content>
            </Card>
        </Wrapper>
    )
}

export default RedirectSection
