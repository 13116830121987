import type { TimelineTemplate, TimelineTemplateId } from '@timelinefyi/types'

import client from '../client'

const list = async (): Promise<TimelineTemplate[]> => {
    const response = await client.get(`/timeline_templates`)
    return response?.data?.data || []
}

const get = async (
    templateId: TimelineTemplateId,
): Promise<TimelineTemplate> => {
    const response = await client.get(`/timeline_templates/${templateId}`)
    return response?.data?.data || null
}

const create = async (
    newTemplate: TimelineTemplate,
): Promise<TimelineTemplate> => {
    const response = await client.post(`/auth/timeline_templates`, newTemplate)
    return response?.data?.data || null
}

const update = async (
    payload: Partial<TimelineTemplate>,
): Promise<TimelineTemplate> => {
    const response = await client.put(`/auth/timeline_templates`, payload)
    return response?.data?.data || null
}

const deleteTemplateById = async (templateId: TimelineTemplateId) => {
    await client.delete(`/auth/timeline_templates/${templateId}`)
}

export const TimelineTemplateApiClient = {
    list,
    get,
    create,
    update,
    deleteTemplateById,
}
