import type { Graph as GraphG6 } from '@antv/g6'
import G6 from '@antv/g6'
import type {
    EdgeId,
    NodeId,
    TimelineClient,
    TimelineTemplate,
} from '@timelinefyi/types'
import React from 'react'
import styled from 'styled-components'

import {
    getPendingEdgeIdsWithoutGraph,
    getPendingNodeIdsWithoutGraph,
} from '../console/helper/overview.helper'
import { EdgeClient, NodeClient } from '../console/type/overview.type'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border: 1px #ccc solid;
    border-radius: 0.3rem;
`

type GraphContainerProps = {
    height?: number
}
const GraphContainer = styled.div<GraphContainerProps>`
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? `${props.height}px` : '400px')};
    cursor: grab;
    display: flex;
    justify-content: center;
    align-content: center;
`

const GraphMinimapContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    width: fit-content;
    background-color: transparent;
    border: 1px #ccc solid;
    border-radius: 0.3rem;
`

const GraphToolbarContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    min-width: 200px;
    min-height: 38px;
`

type Props = {
    template: TimelineTemplate | null | undefined
    timeline: TimelineClient | null
    onNodeClick: (nodeId: NodeId | undefined) => void
    onEdgeClick: (edgeId: EdgeClient | undefined) => void
    height?: number
}

const Graph: React.ForwardRefRenderFunction<GraphG6, Props> = (
    { template, timeline, onNodeClick, onEdgeClick, height },
    forwardedRef,
) => {
    // states
    const [nodes, setNodes] = React.useState<NodeClient[]>([])
    const [edges, setEdges] = React.useState<EdgeClient[]>([])

    // refs
    const graphG6 = React.useRef<GraphG6 | null>(null)
    const graphContainer = React.useRef<HTMLDivElement>(null)
    const graphToolbarContainer = React.useRef<HTMLDivElement>(null)
    const graphMinimapContainer = React.useRef<HTMLDivElement>(null)

    // syncing refs
    React.useEffect(() => {
        if (typeof forwardedRef === 'function') {
            forwardedRef(graphG6.current)
        } else if (forwardedRef) {
            forwardedRef.current = graphG6.current
        }
        // eslint-disable-next-line
    }, [forwardedRef, graphG6.current])

    // modify node and edge data for rendering
    React.useEffect(() => {
        if (!template) return undefined

        const nodesData = template.nodes.map((node) => ({
            id: node.id,
            label: node.name,
        })) as NodeClient[]
        const edgesData = template.edges.map((edge) => ({
            id: edge.id,
            source: edge.source,
            target: edge.target,
        })) as EdgeClient[]

        if (timeline) {
            // marked passed node green, pending one blue
            const checkedNodeIdList = timeline.stages.map(
                (stage) => stage.node.id,
            )
            const stageEdgeIdList = timeline.stages.map(
                (stage) => stage.edge?.id,
            )
            const pendingNodeIdList = getPendingNodeIdsWithoutGraph(timeline)
            const pendingEdgeIdList = getPendingEdgeIdsWithoutGraph(timeline)
            nodesData.forEach((node, index, array) => {
                if (checkedNodeIdList.includes(node.id)) {
                    array[index] = {
                        ...array[index],
                        img: '/assets/check.svg',
                        date: timeline.stages.find(
                            (stage) => stage.node.id === node.id,
                        )?.node.date,
                        type: 'image',
                        size: 38,
                    }
                } else if (pendingNodeIdList.includes(node.id)) {
                    array[index] = {
                        ...array[index],
                        img: '/assets/pending.svg',
                        date: timeline.stages.find(
                            (stage) => stage.node.id === node.id,
                        )?.node.date,
                        type: 'image',
                        size: 38,
                    }
                }
            })
            edgesData.forEach((edge, index, array) => {
                if (stageEdgeIdList.includes(edge.id)) {
                    array[index] = {
                        ...array[index],
                        color: '#7FE183',
                        style: {
                            stroke: '#7FE183',
                            endArrow: {
                                path: 'M 0,0 L 8,4 L 8,-4 Z',
                                fill: '#7FE183',
                            },
                        },
                    }
                } else if (pendingEdgeIdList.includes(edge.id)) {
                    array[index] = {
                        ...array[index],
                        color: '#3053C5',
                        style: {
                            stroke: '#3053C5',
                            endArrow: {
                                path: 'M 0,0 L 8,4 L 8,-4 Z',
                                fill: '#3053C5',
                            },
                        },
                    }
                }
            })
        }
        setNodes(nodesData)
        setEdges(edgesData)
        return () => {
            setNodes([])
            setEdges([])
        }
    }, [timeline, template])

    // graph6 rendering
    React.useEffect(() => {
        const initGraph = () => {
            if (
                graphG6.current ||
                !graphContainer.current ||
                !graphMinimapContainer.current
            )
                return
            const minimap = new G6.Minimap({
                container: graphMinimapContainer.current,
                size: [100, 100],
                className: 'minimap',
                type: 'delegate',
                delegateStyle: {
                    stroke: '#ccc',
                    lineWidth: 1,
                },
            })
            const toolbar = new G6.ToolBar({
                container: graphToolbarContainer.current,
            })

            graphG6.current = new G6.Graph({
                container: graphContainer.current,
                height: graphContainer.current.clientHeight,
                width: graphContainer.current.clientWidth,
                modes: {
                    default: ['drag-canvas'],
                },
                defaultNode: {
                    size: 30,
                    type: 'circle',
                    style: {
                        lineWidth: 2,
                        fill: '#DEE7DA',
                        stroke: '#DEE7DA',
                        cursor: 'pointer',
                    },
                    labelCfg: {
                        position: 'bottom',
                        offset: 10,
                        style: {
                            fill: '#090909',
                            textAlign: 'center',
                        },
                    },
                },
                defaultEdge: {
                    type: 'polyline',
                    color: '#e2e2e2',
                    style: {
                        lineWidth: 5,
                        endArrow: {
                            path: 'M 0,0 L 8,4 L 8,-4 Z',
                            fill: '#e2e2e2',
                        },
                        cursor: 'pointer',
                    },
                },
                layout: {
                    type: 'dagre',
                    rankdir: 'LR',
                    controlPoints: true,
                },
                minZoom: 0.5,
                maxZoom: 2.5,
                fitView: true,
                autoPaint: true,
                animate: true,
                plugins: [minimap, toolbar],
            })
        }

        if (!graphG6.current) {
            initGraph()
        }
        if (graphG6.current) {
            graphG6.current.data({
                nodes,
                edges,
            })
            graphG6.current.setAutoPaint(true)
            graphG6.current?.render()

            graphG6.current.on('node:click', (event) =>
                onNodeClick(event.item?._cfg?.model?.id as NodeId),
            )
            graphG6.current.on('node:touchstart', (event) =>
                onNodeClick(event.item?._cfg?.model?.id as NodeId),
            )
            graphG6.current.on('edge:click', (event) =>
                onEdgeClick(
                    edges.find(
                        (edge) =>
                            edge.id === (event.item?._cfg?.model?.id as EdgeId),
                    ),
                ),
            )
            graphG6.current.on('edge:touchstart', (event) =>
                onEdgeClick(
                    edges.find(
                        (edge) =>
                            edge.id === (event.item?._cfg?.model?.id as EdgeId),
                    ),
                ),
            )
        }
        return () => {}
    }, [edges, nodes, onNodeClick, onEdgeClick, template])

    return (
        <Wrapper>
            <GraphContainer ref={graphContainer} height={height} />
            <GraphMinimapContainer ref={graphMinimapContainer} />
            <GraphToolbarContainer
                className="graph_overview_graph_toolbar"
                ref={graphToolbarContainer}
            />
        </Wrapper>
    )
}

export default React.forwardRef(Graph)
