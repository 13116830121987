import type { Tag, TagId } from '@timelinefyi/types'
import { Cascader } from 'antd'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../hooks/useAuth'
import { CategoryApiClient } from '../../utils/api/clients/category.client'
import { TagApiClient } from '../../utils/api/clients/tag.client'
import { getLastWithDefault } from '../../utils/array.utils'
import { FilterOption } from '../../utils/filter.util'
import {
    companyCategoryId,
    defaultCategoryId,
    defaultTagId,
} from '../category/const.category'
import { categoryListToOptions } from '../category/helper.category'
import { getValueByTagId } from './helper'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
`

interface Props {
    initialTagId: TagId | null
    onSelectPosition: (tag: Tag | null) => void
}

const CompanySelect: React.FC<Props> = ({ initialTagId, onSelectPosition }) => {
    const { user } = useAuth()
    const [tagId, setTagId] = React.useState<TagId | null>()
    const [value, setValue] = React.useState<string[]>([])
    const [options, setOptions] = React.useState<FilterOption[]>([])

    React.useEffect(() => {
        const fetch = async () => {
            const categoryListData = await CategoryApiClient.list({
                filter_by: {
                    parent: companyCategoryId,
                },
            })
            const optionsData = categoryListToOptions(categoryListData)
            setOptions(optionsData)
            if (!initialTagId) {
                setValue([
                    `category-${defaultCategoryId}`,
                    `tag-${defaultTagId}`,
                ])
                setTagId(defaultTagId)
            } else {
                const newValue = getValueByTagId(initialTagId, optionsData)
                setValue(newValue)
            }
        }
        fetch()
    }, [initialTagId, user])

    React.useEffect(() => {
        const fetch = async () => {
            if (!tagId) return
            const tag = await TagApiClient.get(tagId)
            onSelectPosition(tag)
        }
        fetch()
    }, [onSelectPosition, tagId])

    return (
        <Wrapper>
            <Cascader
                style={{
                    width: 'fit-content',
                    minWidth: '330px',
                }}
                showSearch
                options={options}
                value={value}
                onChange={(array) => {
                    const last = getLastWithDefault(array as any[], '')
                    if (last.slice(0, 4) === 'tag-') {
                        setTagId(last.slice(4))
                    }
                    setValue(array as any[])
                }}
                placement="bottomRight"
            />
        </Wrapper>
    )
}

export default CompanySelect
