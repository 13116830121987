import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border: 1px #ccc solid;
    border-radius: 0.3rem;
`

type GraphContainerProps = {
    height?: number
}
const GraphContainer = styled.div<GraphContainerProps>`
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? `${props.height}px` : '400px')};
    cursor: grab;

    display: flex;
    justify-content: center;
    align-content: center;
`

type Props = {
    height?: number
}

const LoadingGraph: React.FC<Props> = ({ height }) => (
    <Wrapper>
        <GraphContainer height={height}>
            <Spin
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                }}
            />
        </GraphContainer>
    </Wrapper>
)

export default LoadingGraph
