import type { CategoryClient, Tag } from '@timelinefyi/types'
import { Tag as AntdTag } from 'antd'

import { isEmpty } from '../../utils/array.utils'
import { FilterOption } from '../../utils/filter.util'

export const tagListToOptions = (tagList: Tag[]) =>
    tagList.map((tag) => ({
        value: `tag-${tag.id}`,
        label: !tag.hiring_freeze ? (
            <span>{tag.name}</span>
        ) : (
            <span>
                <AntdTag color="blue">Hiring Freeze</AntdTag>
                {tag.name}
            </span>
        ),
        isLeaf: true,
    }))

export const categoryListToOptions = (
    categoryList: CategoryClient[],
): FilterOption[] =>
    categoryList.map((data) => {
        const result: FilterOption = {
            value: `category-${data.id}`,
            label: data.name,
            isLeaf: false,
        }
        if (!isEmpty(data.tags)) {
            result.children = tagListToOptions(data.tags)
        } else if (!isEmpty(data.children)) {
            result.children = categoryListToOptions(data.children)
        }
        return result
    })

export const categoryListToOptionsWithoutTags = (
    categoryList: CategoryClient[],
): FilterOption[] =>
    categoryList.map((data) => {
        const result: FilterOption = {
            value: data.id,
            label: data.name,
        }
        if (!isEmpty(data.children)) {
            result.children = categoryListToOptionsWithoutTags(data.children)
        }
        return result
    })
