import type { Graph, Node as NodeG6 } from '@antv/g6'
import type {
    Edge,
    EdgeId,
    Node,
    NodeId,
    TimelineClient,
    TimelineTemplate,
} from '@timelinefyi/types'

import { getLastWithDefault, isEmpty } from '../../../utils/array.utils'
import { NodeClient, OverviewModalActionType } from '../type/overview.type'

export const getPendingNodeIds = (
    timeline: TimelineClient,
    graph: Graph,
): NodeId[] => {
    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        const lastStageNode = graph.findById(lastStage.node.id) as NodeG6
        const nodes = lastStageNode?.getNeighbors('target')
        return nodes?.map((node) => node._cfg?.model?.id as NodeId) || []
    }
    return timeline.timeline_template.start_nodes
}

export const getPendingNodeIdsWithoutGraph = (
    timeline: TimelineClient,
): NodeId[] => {
    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        return (
            timeline.timeline_template.edges
                .filter((edge) => edge.source === lastStage.node.id)
                .map((edge) => edge.target as NodeId) || []
        )
    }
    return timeline.timeline_template.start_nodes
}

export const getPendingNodes = (timeline: TimelineClient): Node[] => {
    const nodeIds = getPendingNodeIdsWithoutGraph(timeline)
    return timeline.timeline_template.nodes.filter((node) =>
        nodeIds.includes(node.id),
    )
}

export const getPendingEdgeIds = (
    timeline: TimelineClient,
    graph: Graph,
): EdgeId[] => {
    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        const lastStageNode = graph.findById(lastStage.node.id) as NodeG6
        const edges = lastStageNode?.getOutEdges()
        return edges?.map((edge) => edge._cfg?.model?.id as EdgeId) || []
    }
    return []
}

export const getPendingEdgeIdsWithoutGraph = (
    timeline: TimelineClient,
): EdgeId[] => {
    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        return (
            timeline.timeline_template.edges
                .filter((edge) => edge.source === lastStage.node.id)
                .map((edge) => edge.id as EdgeId) || []
        )
    }
    return []
}

export const getPendingEdgesByNodeId = (
    nodeId: NodeId,
    template: TimelineTemplate,
): Edge[] => template.edges.filter((edge) => edge.source === nodeId)

export const getPendingNodesByNodeId = (
    nodeId: NodeId,
    template: TimelineTemplate,
): Node[] => {
    const nodeIds = getPendingEdgesByNodeId(nodeId, template).map(
        (x) => x.target,
    )
    return template.nodes.filter((node) => nodeIds.includes(node.id))
}

export const getPreviousEdge = (
    timeline: TimelineClient,
    currentNodeId: NodeId,
) => {
    const previousNodeId = getLastWithDefault(timeline.stages, undefined)?.node
        ?.id

    return timeline.timeline_template.edges.find(
        (edge) =>
            edge.source === previousNodeId && edge.target === currentNodeId,
    )
}

export const isTimelineFinished = (timeline: TimelineClient): boolean => {
    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        return timeline.timeline_template.end_nodes.includes(lastStage.node.id)
    }
    return false
}

export const getIsEdgeLocked = (
    edgeId: EdgeId,
    timeline: TimelineClient,
    graph: Graph,
): boolean => {
    const pendingEdgeIds = getPendingEdgeIds(timeline, graph)
    return (
        !pendingEdgeIds.includes(edgeId) &&
        timeline.stages.find((stage) => stage.edge?.id === edgeId) === undefined
    )
}

export const getModalActionTypeByNodeId = (
    nodeId: NodeId,
    timeline: TimelineClient,
    template: TimelineTemplate,
): OverviewModalActionType => {
    if (isEmpty(timeline.stages)) {
        if (template.start_nodes.includes(nodeId)) {
            return OverviewModalActionType.Update
        }
        return OverviewModalActionType.Redirect
    }

    const pendingNodeIds = getPendingNodeIdsWithoutGraph(timeline)
    const pastStageNodeIds = timeline.stages.map((stage) => stage.node.id)
    if (pendingNodeIds.includes(nodeId) || pastStageNodeIds.includes(nodeId)) {
        return OverviewModalActionType.Update
    }
    return OverviewModalActionType.Redirect
}

export const getNode = (
    nodeId: NodeId | undefined,
    graph: Graph,
): NodeClient | undefined =>
    nodeId
        ? (graph.find('node', (node) => (node._cfg as NodeClient).id === nodeId)
              ?._cfg?.model as NodeClient)
        : undefined

export const getFutureEdges = (timeline: TimelineClient): EdgeId[] => {
    const returned = new Set<EdgeId>()
    const visited = new Set<NodeId>()

    const dfs = (nodeId: NodeId | undefined) => {
        if (!nodeId) return
        visited.add(nodeId)
        const edges = getPendingEdgesByNodeId(
            nodeId,
            timeline.timeline_template,
        )
        edges.forEach((edge) => {
            returned.add(edge.id)
            if (edge.target && !visited.has(edge.target)) {
                dfs(edge.target)
            }
        })
    }

    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        dfs(lastStage.node.id)
        return [...returned]
    }
    return timeline.timeline_template.edges.map((x) => x.id)
}

export const getFutureNodes = (timeline: TimelineClient): EdgeId[] => {
    const visited = new Set<NodeId>()

    const dfs = (nodeId: NodeId | undefined) => {
        if (!nodeId) return
        visited.add(nodeId)
        const edges = getPendingEdgesByNodeId(
            nodeId,
            timeline.timeline_template,
        )
        edges.forEach((edge) => {
            if (edge.target && !visited.has(edge.target)) {
                dfs(edge.target)
            }
        })
    }

    const lastStage = getLastWithDefault(timeline.stages, undefined)
    if (lastStage) {
        dfs(lastStage.node.id)
        return [...visited]
    }
    return timeline.timeline_template.nodes.map((x) => x.id)
}
