import type { EdgeConfig, NodeConfig } from '@antv/g6'
import type { EdgeId } from '@timelinefyi/types'

export enum OverviewModalActionType {
    Redirect = 'redirect', // invalid node to click, need to tell user should start from [...]
    Update = 'update', // need to update a timeline
}

export interface NodeClient extends NodeConfig {
    date?: Date
    content?: string
    img?: string
    color?: string
}

export interface EdgeClient extends EdgeConfig {
    id: EdgeId
    content?: string
}
