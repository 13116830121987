import type {
    GetNodeStatsResponse,
    Node,
    NodeId,
    NodeStats,
    Stage,
    TimelineClient,
} from '@timelinefyi/types'
import { Descriptions, Spin, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { TimelineStatsApiClient } from '../../../../utils/api/clients/stats/timeline.stats'
import { isEmpty } from '../../../../utils/array.utils'
import RedirectSection from '../redirect.stats'
import SubmitNodeDate from './submit.node'

const { Title } = Typography

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: 1em 0;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5em;
`

const Section = styled.section``

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelDescription = styled.div`
    font-size: 0.9rem;
    max-width: 500px;
`

const LabelDescriptionUl = styled.ul`
    padding-left: 2em;
`

const LabelDescriptionOl = styled.ol`
    padding-left: 2em;
`

type Props = {
    node: Node
    nodeList: Node[]
    timeline: TimelineClient
    formSubmitCallback: (timeline: TimelineClient | null, node: Node) => void
    nodeRedirectCallback: (node: NodeId | undefined) => void
    locked: boolean
    isFuture: boolean
    isGodMode: boolean
}

const NodeStatistics: React.FC<Props> = ({
    node,
    nodeList,
    timeline,
    formSubmitCallback,
    nodeRedirectCallback,
    locked,
    isFuture,
    isGodMode,
}) => {
    const [nodeStage, setNodeStage] = React.useState<Stage | null>(
        timeline.stages.find((stage) => stage.node.id === node.id) || null,
    )
    const [initialDate, setInitialDate] = React.useState<Date | null>(
        nodeStage?.node.date || null,
    )
    const [nodeStats, setNodeStats] =
        React.useState<GetNodeStatsResponse | null>(null)
    const [nodeStatsSamePath, setNodeStatsSamePath] =
        React.useState<NodeStats | null>(null)

    React.useEffect(() => {
        setNodeStage(
            timeline.stages.find((stage) => stage.node.id === node.id) || null,
        )
        setInitialDate(nodeStage?.node.date || null)
    }, [node.id, nodeStage?.node.date, timeline])

    React.useEffect(() => {
        const fetch = async () => {
            if (!locked) {
                const nodeStatsSamePathData =
                    await TimelineStatsApiClient.getNodeStatsByTimelineId(
                        timeline.id,
                        node.id,
                    )
                setNodeStatsSamePath(nodeStatsSamePathData)
            }
            const nodeStatsData = await TimelineStatsApiClient.getNodeStats(
                node.id,
            )
            setNodeStats(nodeStatsData)
        }
        fetch()
    }, [locked, node.id, timeline.id])

    const UserWaitingAtThisStep = React.useMemo(() => {
        if (nodeStats) {
            return (
                <span
                    style={{
                        fontSize: '1.15rem',
                    }}
                >
                    {nodeStats.current || 0}
                </span>
            )
        }
        return <Spin />
    }, [nodeStats])

    const UserFollowedThisPath = React.useMemo(() => {
        if (nodeStats) {
            return (
                <span
                    style={{
                        fontSize: '1.15rem',
                    }}
                >
                    {nodeStats.total || 0}
                </span>
            )
        }
        return <Spin />
    }, [nodeStats])

    const UserWaitingAtThisStepSamePath = React.useMemo(() => {
        if (locked) {
            return <Title level={5}>Locked 🔒</Title>
        }
        if (nodeStatsSamePath) {
            return (
                <span
                    style={{
                        fontSize: '1.15rem',
                    }}
                >
                    {nodeStatsSamePath.current || 0}
                </span>
            )
        }
        return <Spin />
    }, [locked, nodeStatsSamePath])

    const UserFollowedThisPathSamePath = React.useMemo(() => {
        if (locked) {
            return <Title level={5}>Locked 🔒</Title>
        }
        if (nodeStatsSamePath) {
            return (
                <span
                    style={{
                        fontSize: '1.15rem',
                    }}
                >
                    {nodeStatsSamePath.total || 0}
                </span>
            )
        }
        return <Spin />
    }, [locked, nodeStatsSamePath])

    return (
        <Wrapper>
            <Content>
                <SubmitNodeDate
                    locked={locked}
                    isGodMode={isGodMode}
                    node={node}
                    timeline={timeline}
                    initialDate={initialDate}
                    formSubmitCallback={formSubmitCallback}
                />
                <RedirectSection
                    locked={locked}
                    isGodMode={isGodMode}
                    nodeList={nodeList}
                    isFuture={isFuture}
                    nodeRedirectCallback={nodeRedirectCallback}
                />
                <Section>
                    <Descriptions bordered>
                        <Descriptions.Item
                            label={
                                <LabelWrapper>
                                    <span>User waiting at this step 👥</span>
                                    <LabelDescription>
                                        Number of users who:
                                        <LabelDescriptionUl>
                                            <li>
                                                Currently waiting at &quot;
                                                <strong>{node.name}</strong>
                                                &quot;
                                            </li>
                                            <li>
                                                Don&apos;t need to follow the
                                                same path with you. As long as
                                                the user is waiting at &quot;
                                                <strong>{node.name}</strong>
                                                &quot;, we count.
                                            </li>
                                        </LabelDescriptionUl>
                                    </LabelDescription>
                                </LabelWrapper>
                            }
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            span={3}
                        >
                            {UserWaitingAtThisStep}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <LabelWrapper>
                                    <span>User reached this step 👥</span>
                                    <LabelDescription>
                                        Number of users who:
                                        <LabelDescriptionUl>
                                            <li>
                                                Might currently waiting at
                                                &quot;
                                                <strong>{node.name}</strong>
                                                &quot; or already moved forward.
                                            </li>
                                            <li>
                                                Don&apos;t need to follow the
                                                same path with you. As long as
                                                the user have reached &quot;
                                                <strong>{node.name}</strong>
                                                &quot;, we count.
                                            </li>
                                        </LabelDescriptionUl>
                                    </LabelDescription>
                                </LabelWrapper>
                            }
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            span={3}
                        >
                            {UserFollowedThisPath}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <LabelWrapper>
                                    <span>
                                        User waiting at this step 👥 [Same Path]
                                    </span>
                                    <LabelDescription>
                                        Number of users who:
                                        <LabelDescriptionOl>
                                            <li>
                                                <span>
                                                    Start from &quot;
                                                    <strong>
                                                        {isEmpty(
                                                            timeline.stages,
                                                        )
                                                            ? 'the same starting point as yours'
                                                            : timeline.stages[0]
                                                                  .node.name}
                                                    </strong>
                                                    &quot;
                                                </span>
                                            </li>
                                            <li>
                                                Follow the exact same path as
                                                yours and have reached &quot;
                                                <strong>{node.name}</strong>
                                                &quot;
                                            </li>
                                            <li>
                                                Currently waiting at &quot;
                                                <strong>{node.name}</strong>
                                                &quot;
                                            </li>
                                        </LabelDescriptionOl>
                                    </LabelDescription>
                                </LabelWrapper>
                            }
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            span={3}
                        >
                            {UserWaitingAtThisStepSamePath}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <LabelWrapper>
                                    <span>
                                        User reached this step 👥 [Same Path]
                                    </span>
                                    <LabelDescription>
                                        Number of users who:
                                        <ol>
                                            <li>
                                                <span>
                                                    Start from &quot;
                                                    <strong>
                                                        {isEmpty(
                                                            timeline.stages,
                                                        )
                                                            ? node.name
                                                            : timeline.stages[0]
                                                                  .node.name}
                                                    </strong>
                                                    &quot;
                                                </span>
                                            </li>
                                            <li>
                                                Follow the exact same path as
                                                yours and have reached &quot;
                                                <strong>{node.name}</strong>
                                                &quot;
                                            </li>
                                            <li>
                                                Might currently waiting at
                                                &quot;
                                                <strong>{node.name}</strong>
                                                &quot; or already moved forward.
                                            </li>
                                        </ol>
                                    </LabelDescription>
                                </LabelWrapper>
                            }
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            span={3}
                        >
                            {UserFollowedThisPathSamePath}
                        </Descriptions.Item>
                    </Descriptions>
                </Section>
            </Content>
        </Wrapper>
    )
}

export default NodeStatistics
