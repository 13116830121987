import EditorJS, { OutputData } from '@editorjs/editorjs'
import type { Node, Stage, Timeline, TimelineClient } from '@timelinefyi/types'
import { Alert, Button, Card, Form, message, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import useAuth from '../../../../hooks/useAuth'
import { TimelineApiClient } from '../../../../utils/api/clients/timeline.client'
import { TimelineTemplateApiClient } from '../../../../utils/api/clients/timeline_template'
import { upsertWithCopy } from '../../../../utils/array.utils'
import DatePicker from '../../../date/picker.date'
import ForwardRefEditor from '../../../editor/forward.editor'
import { getPreviousEdge } from '../../helper/overview.helper'

const { Title } = Typography

const Section = styled.section``

const DatePickerWrapper = styled.div`
    display: flex;
    gap: 0.5em;
`

interface Props {
    isGodMode: boolean
    locked: boolean
    node: Node
    timeline: TimelineClient
    initialDate: Date | null
    formSubmitCallback: (timeline: TimelineClient | null, node: Node) => void
}

const SubmitNodeDate: React.FC<Props> = ({
    isGodMode,
    locked,
    node,
    timeline,
    initialDate,
    formSubmitCallback,
}) => {
    const { user, isAdmin } = useAuth()
    const [form] = Form.useForm()

    const editorRef = React.useRef<EditorJS | null>(null)
    const [content, setContent] = React.useState<OutputData | null>(null)
    const [isButtonLoading, setIsButtonLoading] = React.useState(false)

    React.useEffect(() => {
        form.setFieldsValue({
            dayjsDate: initialDate ? dayjs(initialDate) : undefined,
        })
        setContent(node.content ? JSON.parse(node.content) : null)
    }, [form, initialDate, node.content])

    const handleEdit = async (event: React.MouseEvent) => {
        try {
            setIsButtonLoading(true)
            event.preventDefault()
            if (!editorRef.current) return
            const template = timeline.timeline_template
            const newContentRaw = await editorRef.current.save()
            const newContent = JSON.stringify(newContentRaw)
            const newNodes = upsertWithCopy(template.nodes, {
                id: node.id,
                name: node.name,
                date: node?.date,
                content: newContent,
            } as Node)
            await TimelineTemplateApiClient.update({
                id: template.id,
                nodes: newNodes,
            })
            setContent(newContentRaw)
            message.success('Saved!')
        } finally {
            setIsButtonLoading(false)
        }
    }

    const handleSubmit = React.useCallback(
        async ({ dayjsDate }: { dayjsDate: dayjs.Dayjs }) => {
            try {
                setIsButtonLoading(true)
                if (!user) {
                    message.error('Please login first!')
                    return
                }
                const edge = getPreviousEdge(timeline, node.id)
                const oldStage = timeline.stages.find(
                    (x) => x.node.id === node.id,
                )
                const newStage = oldStage
                    ? {
                          ...oldStage,
                          node: {
                              id: node.id,
                              name: node.name as string,
                              date: dayjsDate
                                  ? new Date(dayjsDate.toDate().toDateString())
                                  : null,
                          },
                      }
                    : {
                          id: uuidv4(),
                          node: {
                              id: node.id,
                              name: node.name as string,
                              date: dayjsDate
                                  ? new Date(dayjsDate.toDate().toDateString())
                                  : null,
                          },
                          edge,
                      }
                const newStageList: Stage[] = upsertWithCopy(
                    timeline.stages,
                    newStage,
                )
                newStageList.forEach((stage: Stage) => {
                    stage.node.date = stage.node.date
                        ? new Date(new Date(stage.node.date).toDateString())
                        : undefined
                })
                const timelineData: Partial<Timeline> = {
                    id: timeline!.id,
                    stages: newStageList,
                }
                await TimelineApiClient.update(timelineData)
                const updatedTimeline = await TimelineApiClient.get(timeline.id)
                message.success('Success!')
                formSubmitCallback(updatedTimeline, node)
            } finally {
                setIsButtonLoading(false)
            }
        },
        [formSubmitCallback, node, timeline, user],
    )

    const formContent = React.useMemo(() => {
        if (locked) {
            return <div />
        }
        if (!isGodMode) {
            return (
                <Section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                    }}
                >
                    <Alert
                        message={
                            <span>
                                Warning: submit <strong>fake data</strong> will
                                affect your and other people&apos;s user
                                experience and{' '}
                                <strong>destroy the whole purpose</strong> of
                                this platform.
                            </span>
                        }
                        type="warning"
                        showIcon
                        closable
                    />
                    <Title level={5}>
                        {initialDate ? (
                            <>
                                <span
                                    style={{
                                        fontWeight: 400,
                                    }}
                                >
                                    You reach this stage at{' '}
                                </span>
                                <strong>
                                    {dayjs(initialDate).format('MMM DD, YYYY')}{' '}
                                    🎉🎉🎉
                                </strong>
                            </>
                        ) : (
                            <span>
                                Please submit your date to unlock the next stage
                            </span>
                        )}
                    </Title>
                    <Form onFinish={handleSubmit} form={form}>
                        <DatePickerWrapper>
                            <Form.Item
                                name="dayjsDate"
                                initialValue={
                                    initialDate ? dayjs(initialDate) : undefined
                                }
                            >
                                <DatePicker />
                            </Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isButtonLoading}
                            >
                                {!initialDate ? 'Submit' : 'Update'}
                            </Button>
                        </DatePickerWrapper>
                    </Form>
                </Section>
            )
        }

        const isUnlockedStage = timeline.stages.find(
            (stage) => stage.node.id === node.id,
        )
        if (isUnlockedStage) {
            return <div />
        }

        return (
            <Section
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1em',
                }}
            >
                <Alert
                    type="success"
                    message="You're in God Mode 💫. You don't need to submit your time to unlock this stage"
                />
                <Form onFinish={handleSubmit}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isButtonLoading}
                    >
                        Choose this step
                    </Button>
                </Form>
            </Section>
        )
    }, [
        form,
        handleSubmit,
        initialDate,
        isButtonLoading,
        isGodMode,
        locked,
        node.id,
        timeline.stages,
    ])

    return (
        <Card
            title={
                <span style={{ fontSize: '1.15rem' }}>
                    <strong>Stage:</strong> {node.name as string}
                </span>
            }
        >
            <Section>
                <ForwardRefEditor
                    ref={editorRef}
                    content={content}
                    config={{
                        readOnly: !isAdmin,
                    }}
                    fullWidth
                />
                {isAdmin && (
                    <Button loading={isButtonLoading} onClick={handleEdit}>
                        Save
                    </Button>
                )}
            </Section>
            <div>{formContent}</div>
        </Card>
    )
}

export default SubmitNodeDate
