import type {
    EdgeId,
    EdgeStats,
    GetEdgeStatsResponse,
    GetNodeStatsResponse,
    NodeId,
    NodeStats,
    PathStats,
    StateByDateStats,
    TimelineId,
    TimelineTemplateId,
} from '@timelinefyi/types'

import client from '../../client'

const getNodeStats = async (nodeId: NodeId): Promise<GetNodeStatsResponse> => {
    const response = await client.get(`/stats/node/${nodeId}`)
    return response?.data?.data || null
}

const getNodeStatsByTimelineId = async (
    timelineId: TimelineId,
    nodeId: NodeId,
): Promise<NodeStats> => {
    const response = await client.get(
        `/stats/timeline/${timelineId}/node/${nodeId}`,
    )
    return response?.data?.data || null
}

const getEdgeStats = async (edgeId: EdgeId): Promise<GetEdgeStatsResponse> => {
    const response = await client.get(`/stats/edge/${edgeId}`)
    return response?.data?.data || null
}

const getEdgeStatsByTimelineId = async (
    edgeId: EdgeId,
    timelineId: TimelineId,
): Promise<EdgeStats> => {
    const response = await client.get(
        `/stats/timeline/${timelineId}/edge/${edgeId}`,
    )
    return response?.data?.data || null
}

const getPathStatsByTimelineId = async (
    timelineId: TimelineId,
): Promise<PathStats> => {
    const response = await client.get(`/stats/timeline/${timelineId}/path`)
    return response?.data?.data || null
}

const getStateCountByDate = async (
    templateId: TimelineTemplateId,
): Promise<StateByDateStats> => {
    const response = await client.get(
        `/stats/template/${templateId}/stateByDate`,
    )
    return response?.data?.data || null
}

export const TimelineStatsApiClient = {
    getNodeStats,
    getEdgeStats,
    getNodeStatsByTimelineId,
    getEdgeStatsByTimelineId,
    getPathStatsByTimelineId,
    getStateCountByDate,
}
