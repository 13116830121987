import axios from 'axios'

export const handleCatchCode = (
    error: any,
    defaultValue: any,
    catchCode?: number,
) => {
    if (axios.isAxiosError(error) && error.response?.status === catchCode) {
        return defaultValue
    }
    throw error
}
