import type { ListTagRequest, Tag, TagId } from '@timelinefyi/types'

import client from '../client'

const get = async (id: TagId): Promise<Tag | null> => {
    const response = await client.get(`/tags/${id}`)
    return response?.data?.data || null
}

const list = async (payload: ListTagRequest): Promise<Tag[]> => {
    const response = await client.post(`/tags`, payload)
    return response?.data?.data || []
}

const create = async (newTag: Tag): Promise<Tag> => {
    const response = await client.post(`/auth/tags`, newTag)
    return response?.data?.data || null
}

const update = async (newTag: Tag): Promise<Tag> => {
    const response = await client.put(`/auth/tags`, newTag)
    return response?.data?.data || null
}

const deleteTagById = async (id: TagId) => {
    await client.delete(`/auth/tags/${id}`)
}

export const TagApiClient = {
    get,
    list,
    create,
    update,
    deleteTagById,
}
