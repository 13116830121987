import countBy from 'lodash/countBy'
import React from 'react'

import { convertUnixToDay } from '../../utils/day.util'
import Chart from '../chart/Chart'

interface Props {
    data: number[]
}

const WaitTimeBarChart: React.FC<Props> = ({ data }) => {
    const [dataset, setDataset] = React.useState<any>({
        source: [],
    })

    React.useEffect(() => {
        const days = data
            .map((unix) => convertUnixToDay(unix))
            .map((x) => Math.floor(x))
            .sort((x, y) => x - y)

        const counts = countBy(days)
        const newDataset = {
            source: [
                ['day', 'count'],
                ...Object.keys(counts).map((key) => [
                    `${key} days`,
                    counts[key],
                ]),
            ],
        }
        setDataset(newDataset)
    }, [data])

    return (
        <Chart
            options={{
                tooltip: {},
                dataset,
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 100,
                        xAxisIndex: [0, 1],
                    },
                    {
                        type: 'inside',
                        realtime: true,
                        start: 30,
                        end: 70,
                        xAxisIndex: [0, 1],
                    },
                ],
                xAxis: {
                    type: 'category',
                    name: 'Wait days',
                    nameTextStyle: {
                        fontSize: 16,
                    },
                },
                yAxis: {
                    name: 'How many people wait this long',
                    nameTextStyle: {
                        fontSize: 16,
                    },
                },
                series: [
                    {
                        type: 'bar',
                        encode: {
                            x: 'day',
                            y: 'count',
                        },
                    },
                ],
            }}
        />
    )
}

export default WaitTimeBarChart
