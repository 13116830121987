import type EditorJS from '@editorjs/editorjs'
import dynamic from 'next/dynamic'
import React from 'react'

const Editor = dynamic(() => import('./editor'), {
    ssr: false,
})

interface Props extends React.ComponentProps<'div'> {
    config?: Partial<EditorJS.EditorConfig>
    content: EditorJS.OutputData | null
    fullWidth?: boolean
}

const ForwardRefEditor = React.forwardRef<EditorJS | null, Props>(
    ({ config, content, fullWidth }, ref) => {
        const onEditorChange = React.useCallback(
            (editor: EditorJS) => {
                if (typeof ref === 'function') {
                    ref(editor)
                } else if (ref) {
                    ref.current = editor
                }
            },
            [ref],
        )

        return (
            <Editor
                config={config}
                content={content}
                fullWidth={fullWidth}
                onEditorChange={onEditorChange}
            />
        )
    },
)

export default ForwardRefEditor
