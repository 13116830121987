import React from 'react'
import styled from 'styled-components'

type Props = {
    color: string
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`

const Body = styled.div<Props>`
    border: 0;
    width: 100%;
    background-color: ${(props) => props.color};
    height: 3px;
`

const Point = styled.div<Props>`
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid ${(props) => props.color};
`

const Arrow: React.FC<Props> = ({ color }) => (
    <Wrapper>
        <Body color={color} />
        <Point color={color} />
    </Wrapper>
)

export default Arrow
