const COUNT_ABBRS = ['', 'k', 'm', 'b']

function isInt(n: number) {
    return n % 1 === 0
}

export const formatNumber = (
    number: number,
    withAbbr: boolean = true,
    decimals: number = 2,
): string => {
    if (number < 1000) {
        return isInt(number) ? `${number}` : `${number.toFixed(decimals)} `
    }
    const i =
        number === 0 ? number : Math.floor(Math.log(number) / Math.log(1000))
    const resultNumber = parseFloat((number / 1000 ** i).toPrecision(decimals))

    let result: string = resultNumber.toString()
    if (result.length < decimals) {
        result += `.${'0'.repeat(decimals - result.length)} `
    }
    if (withAbbr) {
        result += `${COUNT_ABBRS[i]} `
    }
    return result
}
