import type { TagId } from '@timelinefyi/types'

import { isEmpty } from '../../utils/array.utils'
import type { FilterOption } from '../../utils/filter.util'

export const getValueByTagId = (
    tagId: TagId,
    options: FilterOption[],
): string[] => {
    const traverse: any[] = []
    // eslint-disable-next-line no-restricted-syntax
    for (const option of options) {
        const dfs = (data: FilterOption): boolean => {
            traverse.push(data.value)
            if (data.value === `tag-${tagId}`) {
                return true
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const x of data?.children || []) {
                if (dfs(x)) {
                    return true
                }
            }
            traverse.pop()
            return false
        }
        dfs(option)
        if (!isEmpty(traverse)) {
            break
        }
    }
    return traverse
}
