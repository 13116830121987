import type { NodeId, TimelineTemplate } from '@timelinefyi/types'

import { getPendingNodesByNodeId } from '../components/console/helper/overview.helper'
import { isEmpty } from './array.utils'

export const getNodeRank = (
    template: TimelineTemplate,
): Map<NodeId, number> => {
    const queue: NodeId[] = [...template.start_nodes] || []
    let rankNumber = 0
    const rank = new Map<NodeId, number>()
    template.start_nodes.forEach((id) => {
        rank.set(id, rankNumber)
        rankNumber += 1
    })

    while (!isEmpty(queue)) {
        const currentNodeId: NodeId = queue.shift() as NodeId
        // eslint-disable-next-line no-loop-func
        getPendingNodesByNodeId(currentNodeId, template).forEach((node) => {
            if (!rank.has(node.id)) {
                rank.set(node.id, rankNumber)
                rankNumber += 1
                queue.push(node.id)
            }
        })
    }
    return rank
}
