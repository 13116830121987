import type { Timeline } from '@timelinefyi/types'

export const SampleTimelineId = 'e51f3495-35e2-455a-a59c-637986e8d977'
export const PhoneInterviewNodeId = '67fe70a8-92fe-4d75-a2ba-aa90951378a3'
export const SampleTimeline: Timeline = {
    id: 'e51f3495-35e2-455a-a59c-637986e8d977',
    description: '',
    meta: {
        creator: '92c8c6ac-2e48-4de3-b41c-226f2d26a6ff',
        createAt: new Date(1510133881000),
    },
    stages: [
        {
            id: 'a71e726c-fc19-45a5-9e94-97933e062706',
            node: {
                id: '2c6b3272-0ade-406f-96b5-f64a3cfb6218',
                name: 'Directly Apply Online',
                date: new Date(1641945600000),
                content:
                    'Mention voice choice this professional. Little third accept get great summer. Table security newspaper interesting. Agreement use tonight fly success should present end. Health here between ahead figure few four. School collection billion talk central help eye.',
            },
        },
        {
            id: 'fd3aec12-0b81-48f2-a938-e96526cb0591',
            node: {
                id: '4d419037-251b-4bbb-a83b-7b1c39b7302a',
                name: 'Hello from Meta',
                date: new Date(1642809600000),
                content:
                    'Interesting experience college usually measure discover owner. Tend it myself high. Condition effort price peace. Image cup good part worker close out. Military court avoid. Song us sport pretty issue dinner owner. Stop current line speak make step might. Determine remember early show rich skin. Military hour message key box accept hard.',
            },
            edge: {
                id: '4ce394d2-8cb4-49ae-8b83-dd2042a42b63',
                source: '2c6b3272-0ade-406f-96b5-f64a3cfb6218',
                target: '4d419037-251b-4bbb-a83b-7b1c39b7302a',
                content:
                    'Between three foot present receive simply raise. Miss walk why family product industry. Foot commercial then himself network point resource. Center happy least than.',
            },
        },
        {
            id: '8f2542b0-171b-4138-bf70-a60f01506061',
            node: {
                id: '81958169-fda5-4bd5-a197-02dddb62c72a',
                name: 'Submit Availability',
                date: new Date(1644364800000),
                content:
                    'Standard fine question east education simple message. Important too tough western base.',
            },
            edge: {
                id: '8a333f97-c184-4933-b896-e3c556334e92',
                source: '4d419037-251b-4bbb-a83b-7b1c39b7302a',
                target: '81958169-fda5-4bd5-a197-02dddb62c72a',
                content:
                    'Experience accept already which participant billion. Put never range yes eye everyone short. Operation I majority.',
            },
        },
        {
            id: 'e24df122-8ea6-4594-8861-4015bb55fa86',
            node: {
                id: '9fd564ce-e957-4530-bcaa-2cfea5e40f5f',
                name: 'Phone Interview Scheduled',
                date: new Date(1645228800000),
                content: '',
            },
            edge: {
                id: 'f7461ee3-b51f-4b45-9c5a-5388d27fb289',
                source: '81958169-fda5-4bd5-a197-02dddb62c72a',
                target: '9fd564ce-e957-4530-bcaa-2cfea5e40f5f',
                content:
                    'Home feel foreign ability stay be president. Weight society lot. Seem let to natural as.',
            },
        },
        {
            id: 'ad62f948-0ff0-4bd0-afe7-bb921bb06c21',
            node: {
                id: '67fe70a8-92fe-4d75-a2ba-aa90951378a3',
                name: 'Phone Interview',
                date: new Date(1646524800000),
                content: 'Finally challenge tell traditional.',
            },
            edge: {
                id: 'd98bb80f-8103-4dd9-bfd0-51c278d78b02',
                source: '9fd564ce-e957-4530-bcaa-2cfea5e40f5f',
                target: '67fe70a8-92fe-4d75-a2ba-aa90951378a3',
                content: '',
            },
        },
        {
            id: 'f4c999c5-a8aa-4f3a-8611-3dae3463d983',
            node: {
                id: '2bf09411-09e2-45cd-8484-206cfe92371d',
                name: 'Passed Schedule Onsite',
                date: new Date(1647820800000),
                content: 'Tv the lot event even.',
            },
            edge: {
                id: '1484d3a6-9cbc-4491-ac37-05d27d7be87b',
                source: '67fe70a8-92fe-4d75-a2ba-aa90951378a3',
                target: '2bf09411-09e2-45cd-8484-206cfe92371d',
                content:
                    'Short low audience. Offer garden between let pressure laugh happy task.',
            },
        },
        {
            id: '1dc43817-ef7e-44a3-b4dd-d0c9b9184f23',
            node: {
                id: 'cdeb5c4d-1c4e-45e4-985c-797268c89480',
                name: 'Onsite Interview Scheduled',
                date: new Date(1648857600000),
                content:
                    'Behind less enjoy. Surface little maintain partner blue.',
            },
            edge: {
                id: '1294845c-13fa-4434-bf9c-5ded8de3ba70',
                source: '2bf09411-09e2-45cd-8484-206cfe92371d',
                target: 'cdeb5c4d-1c4e-45e4-985c-797268c89480',
                content:
                    'About list husband anything. Cause fight performance various police resource executive beautiful.',
            },
        },
        {
            id: '8bc59009-674b-4385-b918-0246871aacb1',
            node: {
                id: '5397106e-490a-4112-9d9a-a62cfe27bcb4',
                name: 'Onsite Interview',
                date: new Date(1650326400000),
                content:
                    'Officer cultural away total enjoy sometimes. Travel structure perform president build family a need.',
            },
            edge: {
                id: '5c8373db-91d6-4d3f-a36d-1f732799cf92',
                source: 'cdeb5c4d-1c4e-45e4-985c-797268c89480',
                target: '5397106e-490a-4112-9d9a-a62cfe27bcb4',
                content: 'All local at themselves ten.',
            },
        },
        {
            id: 'cdb2026d-e7e0-4e47-813d-d3a768c1a097',
            node: {
                id: '3ea10829-2aee-4c2b-b047-760ca7270473',
                name: 'Schedule HR Call',
                date: new Date(1651622400000),
                content: '',
            },
            edge: {
                id: '283b6f08-591f-4c3c-8d5c-9a79517f1cc8',
                source: '5397106e-490a-4112-9d9a-a62cfe27bcb4',
                target: '3ea10829-2aee-4c2b-b047-760ca7270473',
                content:
                    'Usually treat baby picture anyone. Hour lawyer feeling affect system pick affect. Result yard magazine oil bring give hard. Hundred suggest represent. Such they culture option simply hundred leader.',
            },
        },
        {
            id: '0f76cf18-3868-4622-95e9-ac825f3be7c9',
            node: {
                id: '90cd59a7-ee03-425e-85a0-49e0449055d3',
                name: 'Offer Email',
                date: new Date(1653091200000),
                content:
                    'Look whom position enough black. How industry generation model use answer. Then court tax industry treatment main could. Leave it ok source those field leave late. Republican story develop. Reduce fine ability middle.',
            },
            edge: {
                id: '64f39437-7f7e-4b5d-9c39-0c919399585c',
                source: '3ea10829-2aee-4c2b-b047-760ca7270473',
                target: '90cd59a7-ee03-425e-85a0-49e0449055d3',
                content:
                    'Produce sound than news. East election star become. Reveal different reason. Attack up cold attack town.',
            },
        },
    ],
    tag_id: 'f3c922c0-6cb0-4670-bbf1-08411048a54b',
    timeline_template_id: '6e53197a-9b43-40fb-b854-8f1505005739',
    title: 'Sample Timeline',
}
