/* eslint-disable func-names, react/no-this-in-sfc, @next/next/no-img-element,react-hooks/exhaustive-deps */
import type { Graph as GraphG6 } from '@antv/g6'
import Modal from '@mui/material/Modal'
import type {
    Node,
    NodeId,
    Tag,
    TagId,
    Timeline,
    TimelineClient,
    TimelineId,
    TimelineMeta,
    TimelineTemplate,
} from '@timelinefyi/types'
import { TimelineType } from '@timelinefyi/types'
import { Button, Empty, message, Select, Tooltip } from 'antd'
import axios from 'axios'
import introJs, { IntroJs } from 'intro.js'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../hooks/useAuth'
import { TagApiClient } from '../../utils/api/clients/tag.client'
import { TimelineApiClient } from '../../utils/api/clients/timeline.client'
import { TimelineTemplateApiClient } from '../../utils/api/clients/timeline_template'
import { getLastWithDefault, isEmpty } from '../../utils/array.utils'
import { IntroCookie } from '../../utils/cookies/intro.cookies'
import JobDescriptionEditor from '../editor/job.description.editor'
import Graph from '../graph/graph'
import LoadingGraph from '../graph/loading.graph'
import CompanySelect from '../select/company.select'
import StepCountBarChart from '../stats/StepCountBarChart'
import GodModeSwitch from '../switch/god.switch'
import { SampleTimelineId } from './const/timeline.const'
import {
    getFutureEdges,
    getFutureNodes,
    getIsEdgeLocked,
    getModalActionTypeByNodeId,
    getNode,
    getPendingNodes,
    isTimelineFinished,
} from './helper/overview.helper'
import CreateModal from './modal/timeline/create.timeline'
import EdgeStatistics from './stats/edge/edge.stats'
import NodeStatistics from './stats/node/node.stats'
import { MyIntroJsStep } from './type/intro.type'
import { EdgeClient, OverviewModalActionType } from './type/overview.type'

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2em;
    scroll-behavior: smooth;
`

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`

const Header = styled.header`
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h1 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 400;
    }
`

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
`

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
    flex-wrap: wrap;
`

const Underbar = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between;
    align-content: center;
`

const SelectWrapper = styled.div`
    display: flex;
    gap: 0.5em;
`

const GodModeWrapper = styled.div`
    display: flex;
    gap: 1em;
    align-content: center;
`

const StatsContent = styled.div`
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-content: center;
`

const EmptyWrapper = styled.div`
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-items: center;
`

type Props = {}

const TimelineConsole: React.FC<Props> = () => {
    const { user } = useAuth()
    const router = useRouter()

    // states for overview section
    const [tag, setTag] = React.useState<Tag | null>(null)
    const [tagId, setTagId] = React.useState<TagId | null>(
        (router.query.tag as any) || null,
    )
    const [timelineMetaList, setTimelineMetaList] = React.useState<
        TimelineMeta[] | null
    >(null)
    const [timelineId, setTimelineId] = React.useState<TimelineId | null>(
        (router.query.timeline as any) || null,
    )
    const [timeline, setTimeline] = React.useState<TimelineClient | null>(null)
    const [template, setTemplate] = React.useState<TimelineTemplate | null>(
        null,
    )
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [statsContent, setStatsContent] = React.useState<React.ReactNode>()
    const [isGodMode, setIsGodMode] = React.useState(true)

    // loading
    const [isGraphLoading, setIsGraphLoading] = React.useState(false)
    const [isRollbackLoading, setIsRollbackLoading] = React.useState(false)

    // introjs
    const [isInIntroJs, setIsInIntroJs] = React.useState(false)

    // select
    const [isTimelineSelectOpen, setIsTimelineSelectionOpen] =
        React.useState(false)

    // ref for graph
    const statisticsRef = React.useRef<HTMLElement | null>(null)
    const graphRef = React.useRef<GraphG6 | null>(null)
    const timelineRef = React.useRef<TimelineClient | null>(timeline)
    const introJsRef = React.useRef<IntroJs | null>(null)
    const introJsStepRef = React.useRef<number | null>(null)

    const refreshAllData = React.useCallback(async () => {
        try {
            setIsGraphLoading(true)
            if (!tag) return
            if (user) {
                const [timelineMetaListData, timelineTemplateData] =
                    await Promise.all([
                        TimelineApiClient.listUserMetasByTagID(tag.id),
                        TimelineTemplateApiClient.get(tag.timeline_template_id),
                    ])
                timelineMetaListData.reverse()
                setTemplate(timelineTemplateData)
                setTimelineMetaList(timelineMetaListData)
            } else {
                const [timelineMetaListData, timelineTemplateData] =
                    await Promise.all([
                        TimelineApiClient.listMetaByTagID(tag.id),
                        TimelineTemplateApiClient.get(tag.timeline_template_id),
                    ])
                timelineMetaListData.reverse()

                setTemplate(timelineTemplateData)
                setTimelineMetaList(timelineMetaListData)
            }
            setStatsContent(null)
        } finally {
            setIsGraphLoading(false)
        }
    }, [tag, user])

    // fetch timeline template and meta data
    React.useEffect(() => {
        const fetchData = async () => {
            await refreshAllData()
        }
        fetchData()
    }, [refreshAllData, tag, timelineId, user])

    // fetch timeline data and path stats
    React.useEffect(() => {
        const fetch = async () => {
            try {
                setIsGraphLoading(true)
                if (!timelineId) return
                try {
                    const timelineData = await TimelineApiClient.getByUser(
                        timelineId,
                    )
                    setTimeline(timelineData)
                    setStatsContent(null)
                } catch (error) {
                    if (
                        axios.isAxiosError(error) &&
                        error.response?.status === 401 &&
                        user
                    ) {
                        message.error(
                            "You don't have access to this timeline! 🥲",
                        )
                        setTimeline(null)
                        setStatsContent(null)
                    }
                    if (!user && router.query.timeline) {
                        message.error(
                            "You don't have access to this timeline! 🥲",
                        )
                        await router.push('/')
                    } else if (!user && timelineId === SampleTimelineId) {
                        const timelineData = await TimelineApiClient.get(
                            timelineId,
                        )
                        setTimeline(timelineData)
                        setStatsContent(null)
                    }
                }
            } finally {
                setIsGraphLoading(false)
            }
        }
        fetch()
    }, [router, timelineId, user])

    React.useEffect(() => {
        if (!tagId) return undefined
        const fetch = async () => {
            try {
                setIsGraphLoading(true)
                const tagData = await TagApiClient.get(tagId)
                setTag(tagData)
            } finally {
                setIsGraphLoading(false)
            }
        }
        fetch()
        return () => {}
    }, [tagId])

    // sync timeline ref
    React.useEffect(() => {
        timelineRef.current = timeline
    }, [timeline])

    // god mode
    React.useEffect(() => {
        if (isGodMode) {
            const fetch = async () => {
                try {
                    setIsGraphLoading(true)
                    if (
                        !timeline ||
                        (!isTimelineFinished(timeline) &&
                            timeline.type !== TimelineType.GOD_MODE)
                    ) {
                        setIsGodMode(false)
                        return
                    }

                    const godModeTimelineData =
                        await TimelineApiClient.getGodTimeline(timeline)
                    setTimelineId(godModeTimelineData.id)
                    setTimelineMetaList([
                        {
                            id: godModeTimelineData.id,
                            meta: {
                                creator: godModeTimelineData.meta.creator.id,
                                createAt: godModeTimelineData.meta.createAt,
                            },
                            title: godModeTimelineData.title,
                        },
                    ])
                } finally {
                    setIsGraphLoading(false)
                }
            }
            fetch()
        }
    }, [isGodMode, router, timeline])

    const onCreateTimeline = React.useCallback(
        (newTimeline: Timeline) => {
            try {
                setIsGraphLoading(true)
                const metaListCopy = timelineMetaList
                    ? [...timelineMetaList]
                    : []
                metaListCopy.push({
                    id: newTimeline.id,
                    title: newTimeline.title,
                    meta: newTimeline.meta,
                })
                metaListCopy.reverse()
                setTimelineMetaList(metaListCopy)
                setTimelineId(newTimeline.id)
                setIsModalOpen(false)
            } finally {
                setIsGraphLoading(false)
            }
        },
        [timelineMetaList],
    )

    const onSelectPosition = React.useCallback((data: Tag | null) => {
        try {
            setIsGraphLoading(true)
            setTagId(data?.id || null)
            setTimeline(null)
            setTimelineId(null)
            setIsGodMode(false)
            if (data) {
                router.replace({
                    query: { ...router.query, tag: data.id },
                })
            }
        } finally {
            setIsGraphLoading(false)
        }
    }, [])

    const onNodeClick = React.useCallback(
        (
            nodeId: NodeId | undefined,
            newTimeline: TimelineClient | null = null,
        ) => {
            if (!nodeId || !timeline || !template) return

            const node = timeline.timeline_template.nodes.find(
                (x) => x.id === nodeId,
            )
            if (!node) return
            const timelineData = newTimeline || timeline
            message.config({ maxCount: 1 })
            message.success('Statistics Updated!')
            const type = getModalActionTypeByNodeId(
                nodeId,
                timelineData,
                template,
            )

            if (isInIntroJs && introJsRef.current) {
                if (introJsStepRef.current === 12) {
                    // @ts-ignore
                    document
                        .getElementById('statistics')
                        .scrollIntoView({ behavior: 'smooth' })
                    introJsRef.current.goToStepNumber(13)
                }
            } else {
                // @ts-ignore
                document
                    .getElementById('statistics')
                    .scrollIntoView({ behavior: 'smooth' })
            }
            const pendingNodes = getPendingNodes(timelineData)
            const futureNodeIds = getFutureNodes(timelineData)

            setIsModalOpen(false)
            setStatsContent(
                <NodeStatistics
                    node={node}
                    nodeList={pendingNodes}
                    timeline={timelineData}
                    formSubmitCallback={(
                        newTimelineData: TimelineClient | null,
                        nodeData: Node,
                    ) => {
                        setTimeline(newTimelineData)
                        setIsModalOpen(false)
                        onNodeClick(nodeData.id, newTimelineData)
                    }}
                    nodeRedirectCallback={onNodeClick}
                    locked={type === OverviewModalActionType.Redirect}
                    isFuture={futureNodeIds.includes(node.id)}
                    isGodMode={isGodMode}
                />,
            )
        },
        [isGodMode, isInIntroJs, template, timeline],
    )

    const onEdgeClick = React.useCallback(
        (edge: EdgeClient | undefined) => {
            if (
                !edge ||
                !timelineRef.current ||
                !timeline ||
                !template ||
                !graphRef.current
            ) {
                return
            }

            message.config({ maxCount: 1 })
            message.success('Statistics Updated!')
            if (isInIntroJs && introJsRef.current) {
                if (introJsStepRef.current === 15) {
                    introJsRef.current.goToStepNumber(16)
                    // @ts-ignore
                    document
                        .getElementById('statistics')
                        .scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                // @ts-ignore
                document
                    .getElementById('statistics')
                    .scrollIntoView({ behavior: 'smooth' })
            }
            const source = getNode(edge.source, graphRef.current)
            const target = getNode(edge.target, graphRef.current)
            const futureEdges = getFutureEdges(timeline)
            const pendingNodes = getPendingNodes(timeline)
            const locked = getIsEdgeLocked(
                edge.id,
                timelineRef.current,
                graphRef.current,
            )
            setStatsContent(
                <EdgeStatistics
                    edge={edge}
                    source={source}
                    target={target}
                    timeline={timelineRef.current}
                    isFutureEdge={futureEdges.includes(edge.id)}
                    nodeList={pendingNodes}
                    nodeRedirectCallback={onNodeClick}
                    locked={locked}
                    isGodMode={isGodMode}
                />,
            )
        },
        [isGodMode, isInIntroJs, onNodeClick, template, timeline],
    )

    const onRollback = async (event: React.MouseEvent) => {
        try {
            setIsGraphLoading(true)
            setIsRollbackLoading(true)
            event.preventDefault()
            if (!timelineId) return
            const newTimelineData = await TimelineApiClient.rollbackGodTimeline(
                timelineId,
            )
            setTimeline(newTimelineData)
            message.success('Success!')
        } finally {
            setIsGraphLoading(false)
            setIsRollbackLoading(false)
        }
    }

    React.useEffect(() => {
        if (!IntroCookie.getNeedIntro() || user) return
        setIsInIntroJs(true)
        introJsRef.current = introJs()
        introJsStepRef.current = 0
        introJsRef.current
            .setOptions({
                tooltipClass: 'graphIntroClass',
                exitOnOverlayClick: false,
                steps: [
                    // 1
                    {
                        title: 'Welcome to timelinefyi! 👋 <br/>A <strong style="color: #0097ff">powerful</strong> and <strong style="color: #36ab16">100% free</strong> platform for your job applications.',
                        intro: 'This platform is powerful but <strong style="font-size: 1rem"> kinda complicated 😢</strong>. Let me show you how it works.',
                        myBeforeChangeFunction: () => {
                            setTimeline(null)
                            setTimelineId(null)
                            introJsStepRef.current = 1
                        },
                        disableInteraction: true,
                    },
                    // 2
                    {
                        element: '.graph_overview',
                        intro: 'This is Overview 🗺 Section.<br/>The <strong style="font-size: 1rem">core 💎</strong> of this entire platform.',
                        disableInteraction: true,
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 2
                        },
                    },
                    // 3
                    {
                        element: '.graph_overview_company_select',
                        intro: 'You can select a company and job position here. 🧐',
                        position: 'left',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 3
                        },
                    },
                    // 4
                    {
                        element: '.graph_overview_graph',
                        intro: 'After selected, this graph will show <strong>all the possible steps and outcome</strong> for the given job position. 🤗',
                        position: 'top',
                        disableInteraction: true,
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 4
                        },
                    },
                    // 5
                    {
                        element: '.graph_overview_graph',
                        intro: 'Feel free to <strong>drag around</strong> and use tools in top left corner to <strong>zoom in</strong> 🔎 and <strong>zoom out</strong> 🔎!',
                        position: 'top',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 5
                        },
                    },
                    // 6
                    {
                        element: '.graph_overview_timeline_select',
                        intro: 'Then you can select a timeline',
                        position: 'top',
                        disableInteraction: true,
                        myChangeFunction: () => {},
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 6
                            setIsTimelineSelectionOpen(true)
                        },
                    },
                    // 7
                    {
                        element: '.graph_overview_timeline_select',
                        intro: "Let's select 'Sample Timeline' here!",
                        position: 'top',
                        disableInteraction: true,
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 7
                            setTimelineId(
                                'e51f3495-35e2-455a-a59c-637986e8d977',
                            )
                            setIsTimelineSelectionOpen(false)
                        },
                    },
                    // 8
                    {
                        element: '.graph_overview_graph',
                        intro: 'Boom!🤯',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 8
                        },
                    },
                    // 9
                    {
                        element: '.graph_overview_graph',
                        intro: 'Some nodes are highlighted as <img src="/assets/check.svg" alt="checked"/> to show you have <strong>completed</strong> this step',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 9
                        },
                    },
                    // 10
                    {
                        element: '.graph_overview_graph',
                        intro: 'Now you can have a clear picture of your path among all the other possible path. 😎',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 10
                        },
                    },
                    // 11
                    {
                        element: '.graph_overview_graph',
                        intro: 'Try to click one of the nodes (<img src="/assets/check.svg" alt="checked"/> or <img src="/assets/empty.svg" alt="empty"/>) in the graph 🤏',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 11
                        },
                    },
                    // 12
                    {
                        element: '.graph_statistics',
                        intro: 'You get lots of <strong>amazing</strong> and <strong>super helpful</strong> statistics here! 🥳<br/>You can explore it by yourself. Let us move forward!',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 12
                        },
                    },
                    // 13
                    {
                        element: '.graph_overview_graph',
                        intro: 'Try to click one of the arrows (like <img src="/assets/Green-Arrow.svg" alt="green-arrow"/>) in the graph 🤏',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 13
                        },
                    },
                    // 14
                    {
                        element: '.graph_statistics',
                        intro: 'Bang! 💥 Another set of more useful statistics.',
                        myAfterChangeFunction: () => {
                            introJsStepRef.current = 14
                        },
                    },
                    // 15
                    {
                        intro: 'Hooray! 🥳 Tutorial finished. Lots of amazing features are still hiding. Go and explore! 🥹',
                        myBeforeChangeFunction: () => {
                            introJsStepRef.current = 15
                        },
                    },
                ] as MyIntroJsStep[],
            })
            .onbeforechange(function (this: any) {
                if (
                    this._introItems[this._currentStep].myBeforeChangeFunction
                ) {
                    this._introItems[this._currentStep].myBeforeChangeFunction()
                }
            })
            .onafterchange(function (this: any) {
                if (this._introItems[this._currentStep].myAfterChangeFunction) {
                    this._introItems[this._currentStep].myAfterChangeFunction()
                }
            })
            .onchange(function (this: any) {
                if (this._introItems[this._currentStep].myChangeFunction) {
                    this._introItems[this._currentStep].myChangeFunction()
                }
            })
            .oncomplete(() => {
                introJsRef.current = null
                setIsInIntroJs(false)
                IntroCookie.setNeedIntro(false)
            })
            .onexit(() => {
                setIsInIntroJs(false)
            })
            .start()
    }, [user])

    const createTimeline = React.useMemo(() => {
        if (isGodMode) {
            return <div />
        }
        if (tag?.hiring_freeze) {
            return (
                <div
                    style={{
                        display: 'flex',
                        gap: '0.5em',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <Tooltip
                        title="Currently hiring freeze!"
                        placement="bottomLeft"
                    >
                        <Button type="primary" disabled>
                            Create timeline
                        </Button>
                    </Tooltip>
                </div>
            )
        }
        return (
            <Tooltip
                color="#2db7f5"
                visible={timelineMetaList !== null && isEmpty(timelineMetaList)}
                title="Create a timeline to unlock the statistics!"
                placement="bottomLeft"
            >
                <Button
                    type="primary"
                    onClick={() => {
                        if (!user) {
                            message.error('Please login first!')
                            return
                        }
                        if (!tag || !template) {
                            message.error('Please select a tag first!')
                            return
                        }
                        setIsModalOpen(true)
                    }}
                >
                    Create timeline
                </Button>
            </Tooltip>
        )
    }, [isGodMode, tag, template, timelineMetaList, user])

    return (
        <Wrapper>
            <Section className="graph_overview">
                <Header>
                    <HeaderLeft>
                        <h1>🗺 Overview</h1>
                    </HeaderLeft>
                    <HeaderRight className="graph_overview_company_select">
                        <h3
                            style={{
                                margin: 0,
                            }}
                        >
                            🏢 Company & Positions:
                        </h3>
                        <CompanySelect
                            initialTagId={tagId}
                            onSelectPosition={onSelectPosition}
                        />
                    </HeaderRight>
                </Header>
                <div className="graph_overview_graph" data-position="bottom">
                    {isGraphLoading ? (
                        <LoadingGraph height={600} />
                    ) : (
                        <Graph
                            height={600}
                            template={template}
                            timeline={timeline}
                            onNodeClick={onNodeClick}
                            onEdgeClick={onEdgeClick}
                            ref={graphRef}
                        />
                    )}
                </div>
                <Underbar>
                    <SelectWrapper className="graph_overview_timeline_select">
                        <Tooltip
                            title="Select a timeline to view the statistics"
                            color="#2db7f5"
                            visible={
                                timelineId === null &&
                                !isEmpty(timelineMetaList) &&
                                !isInIntroJs &&
                                !tag?.hiring_freeze
                            }
                        >
                            <div>
                                <Select
                                    showSearch
                                    placeholder="Select a timeline"
                                    onChange={(value) => {
                                        setTimeline(null)
                                        setTimelineId(value)
                                        router.replace({
                                            query: {
                                                ...router.query,
                                                timeline: value,
                                            },
                                        })
                                    }}
                                    style={{
                                        minWidth: '200px',
                                    }}
                                    value={timelineId}
                                    filterOption={(inputValue, option) => {
                                        if (!option || !option.children)
                                            return true
                                        return (
                                            option.children
                                                // @ts-ignore
                                                .toLowerCase()
                                                .indexOf(
                                                    inputValue.toLowerCase(),
                                                ) >= 0
                                        )
                                    }}
                                    open={
                                        isInIntroJs
                                            ? isTimelineSelectOpen
                                            : undefined
                                    }
                                >
                                    {timelineMetaList?.map((meta) => (
                                        <Select.Option
                                            key={meta.id}
                                            value={meta.id}
                                        >
                                            {meta.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </Tooltip>
                        {createTimeline}
                    </SelectWrapper>
                    <GodModeWrapper>
                        {isGodMode && (
                            <Button
                                type="primary"
                                onClick={onRollback}
                                loading={isRollbackLoading}
                            >
                                Rollback One Step
                            </Button>
                        )}
                        <GodModeSwitch
                            value={isGodMode}
                            timeline={timeline}
                            onChange={async (value) => {
                                setIsGodMode(value)
                                if (!value) {
                                    message.success('God Mode deactivated! 😎')
                                    await refreshAllData()
                                    setTimelineId(
                                        getLastWithDefault(
                                            timelineMetaList || [],
                                            null,
                                        ),
                                    )
                                    setTimeline(null)
                                }
                            }}
                        />
                    </GodModeWrapper>
                </Underbar>
            </Section>
            <Section>
                <JobDescriptionEditor tag={tag} />
            </Section>
            <Section
                id="statistics"
                ref={statisticsRef}
                className="graph_statistics"
            >
                <Header>
                    <HeaderLeft>
                        <h1>📊 Statistics</h1>
                    </HeaderLeft>
                </Header>
                <StatsContent>
                    {statsContent || (
                        <EmptyWrapper>
                            <Empty
                                description={
                                    <div
                                        style={{
                                            fontSize: 20,
                                        }}
                                    >
                                        To view detailed statistics:
                                        <ol
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <li
                                                style={{ width: 'fit-content' }}
                                            >
                                                Select a timeline
                                            </li>
                                            <li
                                                style={{ width: 'fit-content' }}
                                            >
                                                Try to click{' '}
                                                <img
                                                    src="/assets/empty.svg"
                                                    alt="empty"
                                                />{' '}
                                                or{' '}
                                                <img
                                                    src="/assets/Green-Arrow.svg"
                                                    alt="green-arrow"
                                                />{' '}
                                                in the graph.
                                            </li>
                                        </ol>
                                    </div>
                                }
                            />
                        </EmptyWrapper>
                    )}
                </StatsContent>
            </Section>

            <Section>
                <Header>
                    <HeaderLeft>
                        <h1>📈 Charts</h1>
                    </HeaderLeft>
                </Header>
                <div>
                    <StepCountBarChart tag={tag} />
                </div>
            </Section>
            {tag && template && (
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div>
                        <CreateModal
                            tag={tag}
                            template={template}
                            onCreateTimeline={onCreateTimeline}
                        />
                    </div>
                </Modal>
            )}
        </Wrapper>
    )
}

export default TimelineConsole
