import type {
    GetGodTimelineRequest,
    TagId,
    Timeline,
    TimelineClient,
    TimelineId,
    TimelineMeta,
} from '@timelinefyi/types'

import { handleCatchCode } from '../../client.util'
import { parseSortBy } from '../../parse.utils'
import client from '../client'

const get = async (timelineId: TimelineId): Promise<TimelineClient | null> => {
    if (!timelineId) return null
    const response = await client.get(`/timelines/${timelineId}`)
    return response?.data?.data || null
}

const getByUser = async (
    timelineId: TimelineId,
): Promise<TimelineClient | null> => {
    if (!timelineId) return null
    const response = await client.get(`/auth/timelines/${timelineId}`)
    return response?.data?.data || null
}

const getByTagId = async (tagId: TagId): Promise<TimelineClient | null> => {
    if (!tagId) return null
    const response = await client.get(`/auth/timelines/tags/${tagId}`)
    return response?.data?.data || null
}

const list = async (
    page: number = 1,
    pageSize: number = 10,
    sortBy: Map<string, number> = new Map<string, number>(),
): Promise<TimelineClient[]> => {
    const response = await client.get(
        `/timelines?page=${page}&page_size=${pageSize}&${parseSortBy(sortBy)}`,
    )
    return response?.data?.data || []
}

const listByUser = async (
    page: number = 1,
    pageSize: number = 10,
    sortBy: Map<string, number> = new Map<string, number>(),
    catchCode: number | undefined = undefined,
): Promise<TimelineClient[]> => {
    try {
        const response = await client.get(
            `/auth/timelines?page=${page}&page_size=${pageSize}&${parseSortBy(
                sortBy,
            )}`,
        )
        return response?.data?.data || []
    } catch (error) {
        return handleCatchCode(error, [], catchCode)
    }
}

const listIdsByUser = async (): Promise<TimelineId[]> => {
    const response = await client.get(`auth/timelines/ids`)
    return response?.data?.data || []
}

const listUserMetas = async (catchCode?: number): Promise<TimelineMeta[]> => {
    try {
        const response = await client.get(`auth/timelines/metas`)
        return response?.data?.data || []
    } catch (error) {
        return handleCatchCode(error, [], catchCode)
    }
}

const listMetaByTagID = async (tagId: TagId): Promise<TimelineMeta[]> => {
    const response = await client.get(`/timelines/metas/tags/${tagId}`)
    return response?.data?.data || []
}

const listUserMetasByTagID = async (
    tagId: TagId,
    catchCode?: number,
    defaultValue?: any,
): Promise<TimelineMeta[]> => {
    try {
        const response = await client.get(`auth/timelines/metas/tags/${tagId}`)
        return response?.data?.data || []
    } catch (error) {
        return handleCatchCode(error, defaultValue, catchCode)
    }
}

const create = async (timeline: Timeline): Promise<Timeline> => {
    const response = await client.post(`/timelines`, timeline)
    return response?.data?.data || null
}

const update = async (timeline: Partial<Timeline>): Promise<Timeline> => {
    const response = await client.put(`/timelines/${timeline.id}`, timeline)
    return response?.data?.data || null
}

const getGodTimeline = async (
    timeline: TimelineClient,
): Promise<TimelineClient> => {
    const payload: GetGodTimelineRequest = {
        current_timeline: {
            description: timeline.description,
            id: timeline.id,
            meta: {
                creator: timeline.meta.creator.id,
                createAt: timeline.meta.createAt,
            },
            stages: timeline.stages,
            tag_id: timeline.tag.id,
            timeline_template_id: timeline.timeline_template.id,
            title: timeline.title,
        },
    }
    const response = await client.post(`/auth/timelines/god/get`, payload)
    return response?.data?.data || null
}

const rollbackGodTimeline = async (
    timelineId: TimelineId,
): Promise<TimelineClient> => {
    const response = await client.post(
        `/auth/timelines/god/${timelineId}/rollback`,
    )
    return response?.data?.data || null
}

export const TimelineApiClient = {
    get,
    getByUser,
    getByTagId,
    getGodTimeline,
    rollbackGodTimeline,
    list,
    listByUser,
    listMetaByTagID,
    listIdsByUser,
    listUserMetas,
    listUserMetasByTagID,
    create,
    update,
}
