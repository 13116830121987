export const ColorBank = [
    '#e69373',
    '#805240',
    '#e6d5cf',
    '#bf5830',
    '#77d36a',
    '#488040',
    '#d2e6cf',
    '#43bf30',
    '#557aaa',
    '#405c80',
    '#cfd9e6',
    '#306ebf',
    '#ff9900',
    '#b36b00',
    '#ffe6bf',
    '#ffcc80',
    '#00b366',
    '#007d48',
    '#bfffe4',
    '#80ffc9',
    '#400099',
    '#2d006b',
    '#dabfff',
    '#b580ff',
]

export const ColorBankRgb = [
    'rgba(230,147,115,1)',
    'rgba(230,213,207,1)',
    'rgba(191,88,48,1)',
    'rgba(119,211,106,1)',
    'rgba(218,191,255,1)',
    'rgba(181,128,255,1)',
    'rgba(72,128,64,1)',
    'rgba(210,230,207,1)',
    'rgba(45,0,107,1)',
    'rgba(48,110,191,1)',
    'rgba(255,204,128,1)',
    'rgba(85,122,170,1)',
    'rgba(255,153,0,1)',
    'rgba(179,107,0,1)',
    'rgba(64,92,128,1)',
    'rgba(255,230,191,1)',
    'rgba(207,217,230,1)',
    'rgba(0,179,102,1)',
    'rgba(0,125,72,1)',
    'rgba(191,255,228,1)',
    'rgba(128,82,64,1)',
    'rgba(128,255,201,1)',
    'rgba(64,0,153,1)',
    'rgba(67,191,48,1)',
]
