import generatePicker from 'antd/lib/date-picker/generatePicker'
import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

dayjs.extend(weekday)
dayjs.extend(localeData)
// @ts-ignore
const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const ExportedDatePicker = (props: any) => (
    <DatePicker
        {...props}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
    />
)

export default ExportedDatePicker
