import EditorJS, { OutputData } from '@editorjs/editorjs'
import type { Tag } from '@timelinefyi/types'
import { Button, Card, message } from 'antd'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../hooks/useAuth'
import { TagApiClient } from '../../utils/api/clients/tag.client'
import ForwardRefEditor from './forward.editor'

const Section = styled.section``

interface Props {
    tag: Tag | null
}

const JobDescriptionEditor: React.FC<Props> = ({ tag }) => {
    const { isAdmin } = useAuth()
    const editorRef = React.useRef<EditorJS | null>(null)
    const [content, setContent] = React.useState<OutputData | null>(
        tag?.content ? JSON.parse(tag.content) : null,
    )
    const [isButtonLoading, setIsButtonLoading] = React.useState(false)

    React.useEffect(() => {
        setContent(tag?.content ? JSON.parse(tag.content) : null)
    }, [tag])

    const handleEdit = async (event: React.MouseEvent) => {
        try {
            setIsButtonLoading(true)
            event.preventDefault()
            if (!editorRef.current || !tag) return
            const newContentRaw = await editorRef.current.save()
            const newContent = JSON.stringify(newContentRaw)
            const newTag = {
                ...tag,
                content: newContent,
            }
            await TagApiClient.update(newTag)
            setContent(newContentRaw)
            message.success('Saved!')
        } finally {
            setIsButtonLoading(false)
        }
    }

    return (
        <Card>
            <Section>
                <ForwardRefEditor
                    ref={editorRef}
                    content={content}
                    config={{
                        readOnly: !isAdmin,
                    }}
                    fullWidth
                />
                {isAdmin && (
                    <Button loading={isButtonLoading} onClick={handleEdit}>
                        Save
                    </Button>
                )}
            </Section>
        </Card>
    )
}

export default JobDescriptionEditor
