import type {
    Category,
    Tag,
    Timeline,
    TimelineTemplate,
} from '@timelinefyi/types'
import { Button, Divider, Form, Input, message, Spin, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import useAuth from '../../../../hooks/useAuth'
import { CategoryApiClient } from '../../../../utils/api/clients/category.client'
import { TimelineApiClient } from '../../../../utils/api/clients/timeline.client'

const { Title } = Typography

const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: white;
    border: 1px solid #373636;
`

const Content = styled.div`
    margin: 2em;
`

type Props = {
    tag: Tag | null
    template: TimelineTemplate | null
    onCreateTimeline: (timeline: Timeline) => void
}

const CreateModal: React.FC<Props> = ({ tag, template, onCreateTimeline }) => {
    const { user } = useAuth()
    const [category, setCategory] = React.useState<Category | null>(null)

    React.useEffect(() => {
        const fetch = async () => {
            if (!tag) return
            const categoryData = await CategoryApiClient.getById(
                tag.category_id,
            )
            setCategory(categoryData)
        }
        fetch()
    }, [tag])

    const handleSubmit = async ({ name }: { name: string }) => {
        if (!tag || !template) return
        if (!user) {
            message.error('Please login first!')
            return
        }
        const timelineData: Timeline = {
            description: '',
            id: uuidv4(),
            meta: {
                createAt: new Date(),
                creator: user.id,
            },
            stages: [],
            tag_id: tag.id,
            timeline_template_id: template.id,
            title: name,
        }
        const createdTimelineData = await TimelineApiClient.create(timelineData)
        message.success('Success')
        onCreateTimeline(createdTimelineData)
    }

    if (!tag || !template || !category) {
        return <Spin />
    }

    return (
        <Wrapper>
            <Content>
                <Form layout="vertical" onFinish={handleSubmit}>
                    <Title level={4}>Create a new timeline!</Title>
                    <Divider />
                    <Form.Item
                        name="name"
                        label="Timeline name"
                        initialValue={`${category.name} - ${tag.name}`}
                        rules={[
                            {
                                required: true,
                                message: 'Timeline name is required',
                            },
                        ]}
                    >
                        <Input placeholder={`${category.name} - ${tag.name}`} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                </Form>
            </Content>
        </Wrapper>
    )
}

export default CreateModal
