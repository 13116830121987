import * as echarts from 'echarts'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    height: 600px;
`

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
`

type Props = {
    options: echarts.EChartOption | echarts.EChartsResponsiveOption
}

const Chart: React.FC<Props> = ({ options }) => {
    const chartRef = React.useRef<echarts.ECharts | null>(null)
    const canvasRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        const initChart = () => {
            if (!canvasRef.current) return
            chartRef.current = echarts.init(canvasRef.current)
            window.addEventListener('resize', () => chartRef.current?.resize())
        }
        if (!chartRef.current) {
            initChart()
        }
        chartRef.current?.setOption(options)
    }, [options])

    return (
        <Wrapper>
            <ChartContainer ref={canvasRef} />
        </Wrapper>
    )
}

export default Chart
