import type { NextPage } from 'next'
import Head from 'next/head'
import React from 'react'
import styled from 'styled-components'

import TimelineConsole from '../components/console'
import MainLayout from '../layouts/MainLayout'

const Wrapper = styled.main`
    display: flex;
    width: 90%;
    height: 100%;

    flex-direction: column;
    gap: 2em;

    margin-bottom: 2em;
`

type Props = {}

const Home: NextPage<Props> = () => (
    <>
        <Head>
            <title>Home</title>
        </Head>
        <MainLayout>
            <Wrapper>
                <TimelineConsole />
            </Wrapper>
        </MainLayout>
    </>
)

export default Home
