import type {
    EdgeStats,
    GetEdgeStatsResponse,
    GetNodeStatsResponse,
    Node,
    NodeId,
    NodeStats,
    TimelineClient,
} from '@timelinefyi/types'
import {
    Descriptions,
    Divider,
    Select,
    Skeleton,
    Spin,
    Tooltip,
    Typography,
} from 'antd'
import React from 'react'
import styled from 'styled-components'

import { TimelineStatsApiClient } from '../../../../utils/api/clients/stats/timeline.stats'
import { isEmpty } from '../../../../utils/array.utils'
import { convertUnixToDay } from '../../../../utils/day.util'
import { formatNumber } from '../../../../utils/number.utils'
import WaitTimeBarChart from '../../../stats/WaitTimeBarChart'
import { EdgeClient, NodeClient } from '../../type/overview.type'
import RedirectSection from '../redirect.stats'
import BranchView from './branch'

const { Title } = Typography

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: 1em 0;
`

const Content = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5em;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const Section = styled.section``

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelDescription = styled.div`
    font-size: 0.9rem;
    max-width: 400px;
`

const LabelDescriptionUl = styled.ul`
    padding-left: 2em;
`

const LabelDescriptionOl = styled.ol`
    padding-left: 2em;
`

type Props = {
    edge: EdgeClient
    source: NodeClient | undefined
    target: NodeClient | undefined
    timeline: TimelineClient
    nodeList: Node[]
    nodeRedirectCallback: (nodeId: NodeId | undefined) => void
    locked: boolean
    isFutureEdge: boolean
    isGodMode: boolean
}

const EdgeStatistics: React.FC<Props> = ({
    edge,
    source,
    target,
    timeline,
    nodeList,
    nodeRedirectCallback,
    locked,
    isFutureEdge,
    isGodMode,
}) => {
    const timelineId = timeline.id
    const [edgeStats, setEdgeStats] =
        React.useState<GetEdgeStatsResponse | null>(null)
    const [edgeStatsSamePath, setEdgeStatsSamePath] =
        React.useState<EdgeStats | null>(null)
    const [sourceNodeStats, setSourceNodeStats] =
        React.useState<GetNodeStatsResponse | null>(null)
    const [sourceNodeStatsSamePath, setSourceNodeStatsSamePath] =
        React.useState<NodeStats | null>(null)
    const [datasetSource, setDatasetSource] = React.useState<string>('all')

    // fetch resources
    React.useEffect(() => {
        const fetch = async () => {
            if (!source) return
            const [edgeStatsData, sourceNodeStatsData] = await Promise.all([
                TimelineStatsApiClient.getEdgeStats(edge.id),
                TimelineStatsApiClient.getNodeStats(source.id),
            ])
            setEdgeStats(edgeStatsData)
            setSourceNodeStats(sourceNodeStatsData)
            if (!locked) {
                const [edgeStatsDataSamePathData, sourceNodeStatsSamePathData] =
                    await Promise.all([
                        TimelineStatsApiClient.getEdgeStatsByTimelineId(
                            edge.id,
                            timelineId,
                        ),
                        TimelineStatsApiClient.getNodeStatsByTimelineId(
                            timelineId,
                            source.id,
                        ),
                    ])
                setEdgeStatsSamePath(edgeStatsDataSamePathData)
                setSourceNodeStatsSamePath(sourceNodeStatsSamePathData)
            }
        }
        fetch()
    }, [edge.id, locked, source, timelineId])

    const ChoosePathSamePathDescription = React.useMemo(() => {
        if (sourceNodeStatsSamePath && edgeStatsSamePath) {
            return (
                <LabelWrapper>
                    <span>People choose this path 👥 [Same Path]</span>
                    <LabelDescription>
                        Number of users who:
                        <LabelDescriptionOl>
                            <li>
                                <span>
                                    Start from &quot;
                                    <strong>
                                        {!isEmpty(timeline.stages)
                                            ? timeline.stages[0].node.name
                                            : (source?.label as string)}
                                    </strong>
                                    &quot;
                                </span>
                            </li>
                            <li>
                                <span>
                                    <strong>
                                        {sourceNodeStatsSamePath.total || 0}
                                    </strong>{' '}
                                    users follow the exact same path as yours
                                    and reached &quot;
                                    <strong>{source?.label as string}</strong>
                                    &quot;.{' '}
                                    <strong>
                                        {sourceNodeStatsSamePath.current || 0}
                                    </strong>{' '}
                                    of them are currently waiting at that step.
                                    So, ({sourceNodeStatsSamePath.total || 0} -{' '}
                                    {sourceNodeStatsSamePath.current || 0} ={' '}
                                    <strong>
                                        {sourceNodeStatsSamePath.total ||
                                            0 -
                                                sourceNodeStatsSamePath.current ||
                                            0}
                                    </strong>
                                    ) of them moved forward.
                                </span>
                            </li>
                            <li>
                                <span>
                                    <strong>{edgeStatsSamePath.count}</strong>{' '}
                                    of them choose &quot;
                                    <strong>{target?.label as string}</strong>
                                    &quot; as next step.
                                </span>
                            </li>
                        </LabelDescriptionOl>
                    </LabelDescription>
                </LabelWrapper>
            )
        }
        return (
            <LabelWrapper>
                <span>People choose this path 👥 [Same Path]</span>
                <LabelDescription>
                    Number of users who:
                    <LabelDescriptionOl>
                        <li>
                            <span>
                                Start from &quot;
                                <strong>
                                    {!isEmpty(timeline.stages)
                                        ? timeline.stages[0].node.name
                                        : (source?.label as string)}
                                </strong>
                                &quot;
                            </span>
                        </li>
                        <li>
                            <span>
                                Follow the exact same path as yours until &quot;
                                <strong>{source?.label as string}</strong>
                                &quot;
                            </span>
                        </li>
                        <li>
                            <span>
                                Choose &quot;
                                <strong>{target?.label as string}</strong>
                                &quot; as next step.
                            </span>
                        </li>
                    </LabelDescriptionOl>
                </LabelDescription>
            </LabelWrapper>
        )
    }, [
        edgeStatsSamePath,
        source,
        sourceNodeStatsSamePath,
        target,
        timeline.stages,
    ])
    const ChoosePathSamePath = React.useMemo(() => {
        if (locked) {
            return <Title level={5}>Locked 🔒</Title>
        }
        if (edgeStatsSamePath && sourceNodeStatsSamePath) {
            return (
                <span style={{ fontSize: '1.15rem' }}>
                    {edgeStatsSamePath.count}/
                    {sourceNodeStatsSamePath.total ||
                        0 - sourceNodeStatsSamePath.current ||
                        0}{' '}
                    (
                    <strong>
                        {formatNumber(
                            (edgeStatsSamePath.count /
                                (sourceNodeStatsSamePath.total ||
                                    0 - sourceNodeStatsSamePath.current ||
                                    0)) *
                                100,
                            false,
                            1,
                        )}
                        %
                    </strong>
                    )
                </span>
            )
        }
        return <Spin />
    }, [edgeStatsSamePath, locked, sourceNodeStatsSamePath])

    const WaitTimeSamePathDescription = React.useMemo(
        () => (
            <LabelWrapper>
                <span>Average wait time ⏳ [Same Path]</span>
                <LabelDescription>
                    <LabelDescriptionUl>
                        <li>
                            <span>
                                How long do people wait to move from &quot;
                                <strong>{source?.label as string}</strong>
                                &quot; to &quot;
                                <strong>{target?.label as string}</strong>
                                &quot;
                            </span>
                        </li>
                        <li>
                            <span>
                                This only count people who follow{' '}
                                <strong>the same path as yours</strong> all the
                                way from the start.
                            </span>
                        </li>
                    </LabelDescriptionUl>
                </LabelDescription>
            </LabelWrapper>
        ),
        [source, target],
    )
    const WaitTimeSamePath = React.useMemo(() => {
        if (locked) {
            return <Title level={5}>Locked 🔒</Title>
        }
        if (edgeStatsSamePath?.time_diff_avg) {
            return (
                <span style={{ fontSize: '1.15rem' }}>
                    {formatNumber(
                        convertUnixToDay(edgeStatsSamePath.time_diff_avg),
                        true,
                        1,
                    )}{' '}
                    days
                </span>
            )
        }
        return <Spin />
    }, [edgeStatsSamePath, locked])

    const ChoosePathDescription = React.useMemo(() => {
        if (sourceNodeStats && edgeStats) {
            return (
                <LabelWrapper>
                    <span>People choose this path 👥</span>
                    <LabelDescription>
                        Number of users who:
                        <LabelDescriptionOl>
                            <li>
                                <span>
                                    <strong>
                                        {sourceNodeStats.total || 0}
                                    </strong>{' '}
                                    users is previously at &quot;
                                    <strong>{source?.label as string}</strong>
                                    &quot;.{' '}
                                    <strong>
                                        {sourceNodeStats.current || 0}
                                    </strong>{' '}
                                    of them are currently waiting at that step.
                                    So, ({sourceNodeStats.total || 0} -{' '}
                                    {sourceNodeStats.current || 0} ={' '}
                                    <strong>
                                        {sourceNodeStats.total ||
                                            0 - sourceNodeStats.current ||
                                            0}
                                    </strong>
                                    ) of them moved forward.
                                </span>
                            </li>
                            <li>
                                <span>
                                    <strong>{edgeStats.count}</strong> of{' '}
                                    <strong>
                                        {sourceNodeStats.total ||
                                            0 - sourceNodeStats.current ||
                                            0}
                                    </strong>{' '}
                                    choose &quot;
                                    <strong>{target?.label as string}</strong>
                                    &quot; as next step.
                                </span>
                            </li>
                            <li>
                                Users don&apos;t need to follow the same path as
                                yours.
                            </li>
                        </LabelDescriptionOl>
                    </LabelDescription>
                </LabelWrapper>
            )
        }
        return (
            <LabelWrapper>
                <span>People choose this path 👥</span>
                <LabelDescription>
                    Number of users who:
                    <LabelDescriptionOl>
                        <li>
                            <span>
                                Previously at &quot;
                                <strong>{source?.label as string}</strong>
                                &quot;.
                            </span>
                        </li>
                        <li>
                            <span>
                                Choose &quot;
                                <strong>{target?.label as string}</strong>
                                &quot; as next step.
                            </span>
                        </li>
                        <li>
                            Users don&apos;t need to follow the same path as
                            yours.
                        </li>
                    </LabelDescriptionOl>
                </LabelDescription>
            </LabelWrapper>
        )
    }, [edgeStats, source, sourceNodeStats, target])
    const ChoosePath = React.useMemo(() => {
        if (edgeStats && sourceNodeStats) {
            return (
                <span style={{ fontSize: '1.15rem' }}>
                    {edgeStats.count || 0}/
                    {sourceNodeStats.total || 0 - sourceNodeStats.current || 0}{' '}
                    (
                    <strong>
                        {formatNumber(
                            (edgeStats.count /
                                (sourceNodeStats.total ||
                                    0 - sourceNodeStats.current ||
                                    0)) *
                                100 || 0,
                            false,
                            1,
                        )}
                        %
                    </strong>
                    )
                </span>
            )
        }
        return <Spin />
    }, [edgeStats, sourceNodeStats])

    const WaitTimeDescription = React.useMemo(
        () => (
            <LabelWrapper>
                <span>Average wait time ⏳</span>
                <LabelDescription>
                    <LabelDescriptionUl>
                        <li>
                            <span>
                                How long do people wait to move from &quot;
                                <strong>{source?.label as string}</strong>
                                &quot; to &quot;
                                <strong>{target?.label as string}</strong>
                                &quot;
                            </span>
                        </li>
                        <li>
                            <span>
                                Users don&apos;t need to follow the same path as
                                yours. As long as the user move from &quot;
                                <strong>{source?.label as string}</strong>
                                &quot; to &quot;
                                <strong>{target?.label as string}</strong>
                                &quot;, we count.
                            </span>
                        </li>
                    </LabelDescriptionUl>
                </LabelDescription>
            </LabelWrapper>
        ),
        [source, target],
    )
    const WaitTime = React.useMemo(() => {
        if (edgeStats) {
            if (!edgeStats.time_diff_avg) {
                return <span>No data 😢</span>
            }
            return (
                <span style={{ fontSize: '1.15rem' }}>
                    {formatNumber(
                        convertUnixToDay(edgeStats.time_diff_avg),
                        true,
                        1,
                    )}{' '}
                    days
                </span>
            )
        }
        return <Spin />
    }, [edgeStats])

    return (
        <Wrapper>
            <Content>
                <Section>
                    {source && target ? (
                        <BranchView source={source} target={target} />
                    ) : (
                        <Skeleton paragraph={{ rows: 1 }} />
                    )}
                </Section>
                <Section>
                    <RedirectSection
                        locked={locked}
                        isGodMode={isGodMode}
                        isFuture={isFutureEdge}
                        nodeList={nodeList}
                        nodeRedirectCallback={nodeRedirectCallback}
                    />
                </Section>
                <Section>
                    <Descriptions bordered>
                        <Descriptions.Item
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            label={ChoosePathDescription}
                            span={3}
                        >
                            {ChoosePath}
                        </Descriptions.Item>
                        <Descriptions.Item
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            label={WaitTimeDescription}
                            span={3}
                        >
                            {WaitTime}
                        </Descriptions.Item>
                        <Descriptions.Item
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            label={ChoosePathSamePathDescription}
                            span={3}
                        >
                            {ChoosePathSamePath}
                        </Descriptions.Item>
                        <Descriptions.Item
                            labelStyle={{
                                fontSize: '1.15rem',
                            }}
                            label={WaitTimeSamePathDescription}
                            span={3}
                        >
                            {WaitTimeSamePath}
                        </Descriptions.Item>
                    </Descriptions>
                </Section>
                <Divider />
                <Section>
                    <TitleWrapper>
                        <Title level={4}>
                            Average wait time detailed chart ⏳
                        </Title>
                        <Select
                            value={datasetSource}
                            style={{ minWidth: 270 }}
                            onChange={(e) => {
                                setDatasetSource(e)
                            }}
                            placeholder="Select a dataset..."
                        >
                            <Select.Option value="all">All users</Select.Option>
                            <Select.Option disabled={locked} value="same">
                                {locked ? (
                                    <Tooltip
                                        placement="bottomRight"
                                        title="Why it's locked? See above!"
                                    >
                                        <span>
                                            🔒 Users follow the same path with
                                            me
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span>
                                        Users follow the same path with me
                                    </span>
                                )}
                            </Select.Option>
                        </Select>
                    </TitleWrapper>

                    <WaitTimeBarChart
                        data={
                            datasetSource === 'same'
                                ? edgeStatsSamePath?.time_diff || []
                                : edgeStats?.time_diff || []
                        }
                    />
                </Section>
            </Content>
        </Wrapper>
    )
}

export default EdgeStatistics
