import dayjs from 'dayjs'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import Arrow from '../../../arrow'
import { NodeClient } from '../../type/overview.type'

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
`

const NodeWrapper = styled.div`
    display: flex;
    width: fit-content;
    min-width: 200px;
    align-items: center;
    justify-content: center;
    gap: 0.45em;
`

const NodeLeft = styled.div`
    width: 32px;
    height: 32px;
`

const NodeRight = styled.div`
    display: flex;
    flex-direction: column;
`

const NodeRightUpper = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
`

const NodeRightLower = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.85rem;
    font-weight: 700;
    color: #737373;
`

const EdgeWrapper = styled.div`
    width: 100%;
`

type Props = {
    source: NodeClient
    target: NodeClient
}

const BranchView: React.FC<Props> = ({ source, target }) => {
    const sourceImage = (source.img as string) || '/assets/empty.svg'
    const targetImage = (target.img as string) || '/assets/empty.svg'
    const getEdgeColor = () => {
        if (targetImage === '/assets/pending.svg') {
            return '#3053C5'
        }
        if (targetImage === '/assets/check.svg') {
            return '#7FE183'
        }
        return '#e2e2e2'
    }

    return (
        <Wrapper>
            <NodeWrapper>
                <NodeLeft>
                    <Image
                        src={sourceImage}
                        layout="fixed"
                        height={32}
                        width={32}
                    />
                </NodeLeft>
                <NodeRight>
                    <NodeRightUpper>
                        <span>{source.label as string}</span>
                    </NodeRightUpper>
                    <NodeRightLower>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {source.date
                            ? dayjs(source.date).format('MMM DD, YYYY')
                            : sourceImage === '/assets/pending.svg'
                            ? 'Pending'
                            : undefined}
                    </NodeRightLower>
                </NodeRight>
            </NodeWrapper>
            <EdgeWrapper>
                <Arrow color={getEdgeColor()} />
            </EdgeWrapper>
            <NodeWrapper>
                <NodeLeft>
                    <Image
                        src={targetImage}
                        layout="fixed"
                        height={32}
                        width={32}
                    />
                </NodeLeft>
                <NodeRight>
                    <NodeRightUpper>{target.label as string}</NodeRightUpper>
                    <NodeRightLower>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {target.date
                            ? dayjs(target.date).format('MMM DD, YYYY')
                            : targetImage === '/assets/pending.svg'
                            ? 'Pending'
                            : undefined}
                    </NodeRightLower>
                </NodeRight>
            </NodeWrapper>
        </Wrapper>
    )
}

export default BranchView
