import Modal from '@mui/material/Modal'
import type { TimelineClient } from '@timelinefyi/types'
import { Alert, message, Switch, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../hooks/useAuth'
import { isTimelineFinished } from '../console/helper/overview.helper'

const { Text } = Typography

const Wrapper = styled.div`
    display: flex;
    gap: 0.5em;
    align-items: center;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: white;
    border: 1px solid #373636;
`

const ModalContent = styled.div`
    padding: 1em;
`

const ModalTextContent = styled.div`
    padding: 1em 1em 0 1em;
`

interface Props {
    value: boolean
    timeline: TimelineClient | null
    onChange: (value: boolean) => void
}

const GodModeSwitch: React.FC<Props> = ({ value, timeline, onChange }) => {
    const { user } = useAuth()
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    return (
        <Wrapper>
            <Text>God Mode 💫</Text>
            <div>
                <Switch
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                    checked={value}
                    onChange={(newIsOn) => {
                        if (newIsOn) {
                            if (!user) {
                                message.error('Please login first!')
                                return
                            }
                            if (!timeline) {
                                message.error(
                                    'Create a timeline to use god mode!',
                                )
                                return
                            }
                            if (!isTimelineFinished(timeline)) {
                                setIsModalOpen(true)
                                return
                            }
                            message.success('God Mode activated! 😎')
                        }
                        onChange(newIsOn)
                    }}
                />
            </div>

            <Modal
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false)
                }}
            >
                <ModalWrapper>
                    <ModalContent>
                        <Alert
                            showIcon
                            type="warning"
                            message="God mode for this timeline is currently locked 🔒"
                        />
                        {timeline ? (
                            <ModalTextContent>
                                <Text>
                                    God mode for this timeline will be unlocked
                                    once you have completed one of the following
                                    steps:
                                </Text>
                                <ul>
                                    {timeline.timeline_template.end_nodes?.map(
                                        (id) => (
                                            <li key={id}>
                                                <strong>
                                                    {
                                                        // @ts-ignore
                                                        timeline?.timeline_template.nodes.find(
                                                            (x) => x.id === id,
                                                        ).name
                                                    }
                                                </strong>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </ModalTextContent>
                        ) : (
                            <ModalTextContent>
                                <Text>Create a timeline to use god mode!</Text>
                            </ModalTextContent>
                        )}
                    </ModalContent>
                </ModalWrapper>
            </Modal>
        </Wrapper>
    )
}

export default GodModeSwitch
