import { getCookie, setCookie } from 'cookies-next'

const NeedIntroJsTutorialCookieName = `need-onboarding-tutorial`

const getNeedIntro = (): boolean =>
    getCookie(NeedIntroJsTutorialCookieName) !== false

const setNeedIntro = (value: boolean): void =>
    setCookie(NeedIntroJsTutorialCookieName, value, {
        maxAge: 60 * 60 * 24 * 6,
    })

export const IntroCookie = {
    getNeedIntro,
    setNeedIntro,
}
