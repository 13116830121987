import type {
    StateByDateStats,
    Tag,
    TimelineTemplate,
} from '@timelinefyi/types'
import { Alert, Empty, Spin } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'

import { ColorBankRgb } from '../../consts/color'
import { TimelineStatsApiClient } from '../../utils/api/clients/stats/timeline.stats'
import { TimelineTemplateApiClient } from '../../utils/api/clients/timeline_template'
import { getLastWithDefault, isEmpty } from '../../utils/array.utils'
import { getNodeRank } from '../../utils/timeline.util'
import Chart from '../chart/Chart'

const SpinnerWrapper = styled.div`
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-items: center;
`

const EmptyWrapper = styled.div`
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-items: center;
`

interface Props {
    tag: Tag | null
}

const StepCountBarChart: React.FC<Props> = ({ tag }) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [series, setSeries] = React.useState<any[]>([])
    const [dates, setDates] = React.useState<string[]>([])
    const [data, setData] = React.useState<StateByDateStats | null>(null)
    const [template, setTemplate] = React.useState<TimelineTemplate | null>(
        null,
    )

    React.useEffect(() => {
        try {
            setIsLoading(true)
            if (!template || !data || isEmpty(data.stats)) {
                setSeries([])
                setDates([])
                return
            }
            const limit = 60
            const { states } = data

            const datesData = []
            const seriesData: any[] = []
            const stateIndexMap = new Map<string, number>()
            const startDate = dayjs(getLastWithDefault(data.stats, null).date)
            const endDate = dayjs(data.stats[0].date)
            const between = Math.min(endDate.diff(startDate, 'day'), limit)
            const rank = getNodeRank(template)
            states.sort(
                (a: any, b: any) =>
                    (rank.get(
                        template.nodes.find((x) => x.name === a)!.id,
                    ) as number) -
                    (rank.get(
                        template.nodes.find((x) => x.name === b)!.id,
                    ) as number),
            )

            states.forEach((state, index, _) => {
                stateIndexMap.set(state, index)
                seriesData.push({
                    name: state,
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                    },
                    tooltip: {
                        valueFormatter: (value: number) => `${value} users`,
                    },
                    data: Array(between).fill(0),
                    itemStyle: { normal: { color: ColorBankRgb[index] } },
                })
            })

            data.stats.forEach((detail) => {
                const { date, stats } = detail
                const dateIndex = dayjs(date).diff(startDate, 'day')
                if (dateIndex < limit) {
                    stats.forEach((state) => {
                        const stateIndex = stateIndexMap.get(state.name)
                        if (stateIndex) {
                            seriesData[stateIndex].data[dateIndex] = state.count
                        }
                    })
                }
            })

            for (
                let dateTraverser = startDate, counter = 0;
                counter < limit;
                dateTraverser = dateTraverser.add(1, 'day'), counter += 1
            ) {
                datesData.push(dateTraverser.format('MMM DD, YYYY'))
                if (dateTraverser === endDate) break
            }

            setDates(datesData)
            setSeries(seriesData)
        } finally {
            setIsLoading(false)
        }
    }, [data, template])

    React.useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true)
                if (!tag) {
                    setData(null)
                    return
                }
                const templateData = await TimelineTemplateApiClient.get(
                    tag.timeline_template_id,
                )
                const stateCountByDateData =
                    await TimelineStatsApiClient.getStateCountByDate(
                        templateData.id,
                    )
                setTemplate(templateData)
                setData(stateCountByDateData)
            } catch (e) {
                setData(null)
            } finally {
                setIsLoading(false)
            }
        }
        fetch()
    }, [tag])

    if (!tag) {
        return (
            <EmptyWrapper>
                <Empty
                    description={
                        <span style={{ fontSize: 20 }}>
                            Select a timeline to view charts!
                        </span>
                    }
                />
            </EmptyWrapper>
        )
    }

    if (isLoading) {
        return (
            <SpinnerWrapper>
                <Spin />
            </SpinnerWrapper>
        )
    }

    if (isEmpty(series) && !isLoading) {
        return (
            <EmptyWrapper>
                <Empty
                    description={
                        <span
                            style={{
                                fontSize: 20,
                            }}
                        >
                            Current no data for {tag.name}!
                        </span>
                    }
                />
            </EmptyWrapper>
        )
    }

    return (
        <div>
            <Alert
                message={
                    <div>
                        <span>This chart shows:</span>
                        <ul>
                            <li>
                                At each day, how many people reached
                                &quot;XXX&quot; step.
                            </li>
                            <li>
                                You can click the legends to enable/disable some
                                steps you do not wanna see.
                            </li>
                        </ul>
                    </div>
                }
                showIcon
            />
            <br />
            <Chart
                options={{
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                        },
                        formatter: (param) => {
                            if (!param) return 'No data!'
                            if (Array.isArray(param)) {
                                let result = `<span>At <strong>${param[0].name}</strong></span><br/>`
                                const nonEmptyParam = param.filter(
                                    (p) => p.data !== 0,
                                )
                                if (isEmpty(nonEmptyParam)) {
                                    result += `<span>No data this day!<span/>`
                                } else {
                                    nonEmptyParam.forEach((p) => {
                                        result += `<div style="display: flex; justify-content: space-between;"><div style="display: flex; align-items: center; gap: 5px;"><div style="background-color: ${p.color}; width: 20px; height: 10px;"></div><div>${p.seriesName}:</div></div> <span style="padding-left: 10px">${p.value} users</span></div>`
                                    })
                                }
                                return result
                            }
                            return param.data === 0
                                ? ''
                                : `<span>${param.seriesName}: ${param.value}</span>`
                        },
                    },
                    legend: {},
                    grid: {
                        top: 90,
                        left: '3%',
                        right: '4%',
                        bottom: 50,
                        containLabel: true,
                    },
                    axisPointer: {
                        link: [
                            {
                                xAxisIndex: 'all',
                            },
                        ],
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            start: 30,
                            end: 70,
                            xAxisIndex: [0, 1],
                        },
                        {
                            type: 'inside',
                            realtime: true,
                            start: 30,
                            end: 70,
                            xAxisIndex: [0, 1],
                        },
                    ],
                    xAxis: {
                        type: 'category',
                        data: dates,
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series,
                }}
            />
        </div>
    )
}

export default StepCountBarChart
